import { setItem } from "../../common/localStorage";

export const claimsListColumns = (navigate) => [
  {
    title: "Claim Name",
    dataIndex: "claimName",
    key: "claimName",
    sorter: {
      compare: (a, b) => a.claimName.localeCompare(b.claimName),
    },
  },
  {
    title: "Claim type",
    dataIndex: "claimType",
    key: "claimType",
    sorter: {
      compare: (a, b) => a.claimType.localeCompare(b.claimType),
    },
    render: (key) => {
      return (
        <div>{key === "url" && "Redirection Url" || key === "form" && "Form" || key === "quiz" && "Quiz" ||key==="offlineClaim"&&"Offline Claim"}</div>
      );
    },
  },
  {
    title: "Language",
    dataIndex: "language",
  },

  {
    title: "Claim Status",
    dataIndex: "claimStatus",
    key: "claimStatus",
    sorter: {
      compare: (a, b) => a.claimStatus.localeCompare(b.claimStatus),
      multiple: 3,
    },
    render: (key) => {
      return (
        <div className={key === "Active" ? "activted" : "inactive"}>{key}</div>
      );
    },
  },

  {
    title: "Action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div>
            <button
              className="manage"
              onClick={() => {
                navigate("/Add-claim", { state: key });
                setItem("productId", key?.productId);
              }}
            >
              Manage
            </button>
          </div>
        </div>
      );
    },
  },
];
