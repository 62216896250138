import { message } from "antd";
import { convert } from "html-to-text";
import React from "react";

const LanguageSwitch = ({
  manageSwitch,
  setManageSwitch,
  fileUploadDataSelector,
  englishText,
  spanishText,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const manageEnglishSwitch = () => {
    if (fileUploadDataSelector?.data?.data) {
      messageApi.open({
        type: "warning",
        content: "Please Save File First!!",
      });
      setManageSwitch(true);
    } else if (convert(spanishText).trim() !== "") {
      messageApi.open({
        type: "warning",
        content: "Please Save HTML First!!",
      });
    } else {
      setManageSwitch(false);
    }
  };

  const manageSpanishSwitch = () => {
    if (fileUploadDataSelector?.data?.data) {
      messageApi.open({
        type: "warning",
        content: "Please Save File First!!",
      });
      setManageSwitch(false);
    } else if (convert(englishText).trim() !== "") {
      messageApi.open({
        type: "warning",
        content: "Please Save HTML First!!",
      });
    } else {
      setManageSwitch(true);
    }
  };

  return (
    <>
      <div className="switches-container mb-30">
        <div className="mempro" onClick={manageEnglishSwitch}>
          English
        </div>
        <div className="mempro" onClick={manageSpanishSwitch}>
          Spanish
        </div>
        <div
          className={
            !manageSwitch ? "switch-wrapper" : "switch-wrapper switchside"
          }
        >
          <div className="switch">
            {!manageSwitch ? <div>English</div> : <div>Spanish</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default LanguageSwitch;
