import React, { useContext, useEffect, useState } from "react";
import { Switch } from "antd";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loder/loader";
import {
  addProductAction,
  addProductHandler,
} from "../../redux/action/addProductSlice";
import { ThemeContext } from "../../common/themeContext";
import {
  handleKeyPress,
  handleKeyPressSpace,
  handlePaste,
} from "../../common/CommonFunctions";
import {
  getProductFeaturesAction,
  getProductFeaturesHandler,
} from "../../redux/action/getproductFeaturesSlice";
import {
  updateProductAction,
  updateProductHandler,
} from "../../redux/action/updateProduct-Slice";
import { useCommonMessage } from "../../common/CommonMessage";
import { BreadCrum } from "../../common";
import { validationSchemaProducts } from "../../common/Validations";
import { getItem, removeItem, setItem } from "../../common/localStorage";

const Addproduct = () => {
  const [saveProduct, setSaveProduct] = useState(false);
  const [switchInfos, setSwitchInfos] = useState({});
  const messageApi = useCommonMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { brandId } = useContext(ThemeContext);

  const { state } = location;

  // All Selector State Information
  const addProductSelector = useSelector((state) => state?.addProductDetails);
  const getAllFeaturesSelector = useSelector(
    (state) => state?.getProductFeatures
  );
  const updateFeaturesSelector = useSelector(
    (state) => state?.updateProductSlice
  );
  const getProductIdFromStorage = getItem("productId");

  // Handle All Features
  const getNavigateUrl = (item) => {
    switch (item?.refFeatureId) {
      case 9:
        navigate("/disclaimers", { state: item });
        break;
      case 3:
        navigate("/access-membership", { state: item });
        break;
      case 1:
        navigate("/membership-benefits", { state: item });
        break;
      case 2:
        navigate("/plan-information", { state: item });
        break;
      case 7:
        navigate("/summary-plan-description", { state: item });
        break;
      case 12:
        navigate("/schedule-care", { state: item });
        break;
      case 8:
        navigate("/insurance-certification", { state: item });
        break;
      case 5:
        navigate("/digitized-id-card", { state: item });
        break;
      case 11:
        navigate("/claim", { state: item });
        break;
      case 10:
        navigate("/faq", { state: item });
        break;
      case 6:
        navigate("/benefits-history", { state: item });
        break;
      case 4:
        navigate("/dpc-extra", { state: { item: item } });
        break;
      default:
        navigate("/disclaimers");
        break;
    }
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    if (values?.product_name && values?.product_code) {
      let payload = {
        name: values?.product_name?.trim(),
        code: values?.product_code?.trim(),
        refBrandId: brandId,
        activeStatus: "ACTIVE",
      };
      dispatch(addProductHandler(payload));
    }
  };

  useEffect(() => {
    if (
      addProductSelector?.data?.data ||
      state?.data?.data ||
      getProductIdFromStorage
    ) {
      let payload = {
        productId:
          addProductSelector?.data?.data?.id ||
          state?.data?.data?.productId ||
          getProductIdFromStorage,
      };
      dispatch(getProductFeaturesHandler(payload));
    }
  }, [addProductSelector, state, getProductIdFromStorage]);

  // Success And Error Message
  useEffect(() => {
    if (addProductSelector?.data?.message) {
      messageApi.open({
        type: "success",
        content: addProductSelector?.data?.message,
      });
      setSaveProduct(true);
      setItem("productId", addProductSelector?.data?.data?.id);
      dispatch(addProductAction.addProductSliceInfoReset());
    } else if (addProductSelector?.message) {
      messageApi.open({
        type: "error",
        content: addProductSelector?.message,
      });
      dispatch(addProductAction.addProductSliceInfoReset());
    }
  }, [addProductSelector, messageApi]);

  // All Features Switch Handler

  const showSwitchModal = (item) => {
    setSwitchInfos((prevState) => ({
      ...prevState,
      [item?.id]: !prevState[item?.id],
    }));
    if (item?.productId && brandId) {
      let payload = {
        productId: item?.productId,
        refBrandId: brandId,
        productFeature: [
          {
            refFeatureId: item?.refFeatureId,
            GroupLevelStatusEdit: true,
            GroupLevelContentEdit: true,
            status:
              item?.status === "ACTIVE"
                ? "INACTIVE"
                : item?.status === "INACTIVE"
                ? "ACTIVE"
                : "",
          },
        ],
      };
      dispatch(updateProductHandler(payload));
    }
  };
  // Handle Switch Handler
  useEffect(() => {
    if (updateFeaturesSelector?.data?.data) {
      messageApi.open({
        type: "success",
        content: updateFeaturesSelector?.data?.message,
      });
      let payload = {
        productId: updateFeaturesSelector?.data?.data?.id,
      };
      dispatch(getProductFeaturesHandler(payload));
      dispatch(updateProductAction.updateProductInfoReset());
    }
  }, [updateFeaturesSelector]);

  useEffect(() => {
    const newSwitchInfos = {};
    getAllFeaturesSelector?.data?.data?.productFeature?.forEach((element) => {
      if (element.status === "ACTIVE") {
        newSwitchInfos[element.id] = true;
      } else {
        newSwitchInfos[element.id] = false;
      }
    });
    setSwitchInfos(newSwitchInfos);
  }, [getAllFeaturesSelector]);

  return (
    <>
      {(addProductSelector?.isLoading === true ||
        getAllFeaturesSelector?.isLoading === true ||
        updateFeaturesSelector?.isLoading === true) && (
        <Loader loaderTransform="loaderTransform" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.PRODUCTS,
                onClick: () => {
                  navigate("/dashboard");
                  dispatch(addProductAction.addProductSliceInfoReset());
                  dispatch(
                    getProductFeaturesAction.getProductFeaturesInfoReset()
                  );
                  removeItem("productId");
                },
              },
              { text: BreadCrum.ADD_PRODUCT, active: true },
            ]}
          />
          <Formik
            enableReinitialize
            initialValues={{
              product_name: getAllFeaturesSelector?.data?.data?.name || "",
              product_code: getAllFeaturesSelector?.data?.data?.code || "",
            }}
            validationSchema={validationSchemaProducts}
            onSubmit={(values, formikBag) => {
              handleFormSubmit(values, formikBag);
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form className="login-form">
                <div className="table-padding mb-30">
                  <div className="headtitle d-block">
                    <p className=" heedprodct mb-3">Add Product</p>
                    <hr />
                    <div
                      className={
                        !saveProduct === false
                          ? "d-md-flex w-100"
                          : "d-md-flex w-100 mb-30"
                      }
                    >
                      <div
                        className="w-md-50 pe-md-2 mb-lg-0 mb-3 position-relative"
                        controlId="formBasicPassword"
                      >
                        <label className="mb-2">Product Name*</label>
                        <Field
                          type="text"
                          name="product_name"
                          placeholder="Enter your product name"
                          className="form-control"
                          onKeyPress={handleKeyPressSpace}
                          maxLength={30}
                          // onPaste={handlePaste}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="product_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div
                        className=" w-md-50 ps-md-2 position-relative"
                        controlId="formBasicPassword"
                      >
                        <label className="mb-2">Product Code*</label>
                        <Field
                          type="text"
                          name="product_code"
                          placeholder="Enter your product code"
                          className="form-control"
                          onKeyPress={handleKeyPress}
                          // onPaste={handlePaste}
                          maxLength={50}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="product_code"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    {!(
                      saveProduct || getAllFeaturesSelector?.data?.data?.name
                    ) && (
                      <div className="text-end">
                        <button
                          type="submit"
                          className=" sbg border-0 py3 fw-18 gbo continuebtn  savebtn"
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {(saveProduct ||
            getAllFeaturesSelector?.data?.data?.productFeature?.length > 0) && (
            <>
              <div className="grid2 mb-30">
                {getAllFeaturesSelector?.data?.data?.productFeature?.map(
                  (item) => {
                    return (
                      <div className="optionrow">
                        <div className="option">
                          <div className="optionflex">
                            <div className="optiontext">
                              {item?.featureName}
                            </div>
                            <div className="d-flex align-items-center">
                              <Switch
                                className="me-3 switches"
                                checked={switchInfos[item.id]}
                                onChange={() => showSwitchModal(item)}
                              />

                              <button
                                className="manage"
                                onClick={() => {
                                  getNavigateUrl(item);
                                  setItem(
                                    "refFeatureId",
                                    item?.refFeatureId
                                  );
                                  setItem(
                                    "featureName",
                                    item?.featureName
                                  );
                                }}
                                disabled={!switchInfos[item.id]}
                              >
                                Manage
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  onClick={() => {
                    navigate("/dashboard");
                    removeItem("productId");
                  }}
                  className="  border-0 py3 fw-18 gbo ms-auto bgtransparent  addcreatebtn"
                >
                  <span>Go back to listing</span>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Addproduct;
