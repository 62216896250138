export class HttpStatusCode {
    static OK = 200
    static NOT_FOUND = 404
}

export class BreadCrum {
    static HOME = "Home"
    static GROUPS = "Groups"
    static MANAGE_PRODUCTS = "Manage Products"
    static PRODUCTS = "Products"
    static USERS = "Users"
    static ADD_PRODUCT = "Add Product"
    static CLICK_TO_SCHEDULE_CARE = "Click to schedule care"
    static FAQs = "FAQs"
    static HOW_TO_ACCESS_MEMBERSHIP = "How to access Membership"
    static DISCLAIMERS = "Disclaimers"
    static MEMBERSHIP_BENEFITS = "Membership Benefits"
    static CERTIFICATE_OF_INSURANCE = "Certificate of Insurance"
    static DIGITIZED_ID_CARD = "Digitized ID card"
    static EXPLANATION_OF_BENEFIT_HISTORY = "Explanation of Benefits History"
    static PLAN_INFORMATION = "Plan Information"
    static SUMMARY_PLAN_DESCRIPTION = "Summary Plan Description"
    static CLAIM = "Claim"
    static CLAIM_LIST = "Claim List"
    static ADD_CLAIM = "Add Claim"
    static MANAGE_CLAIM = "Manage Claim"
    static EDIT_CLAIM = "Edit Claim"
    static REDIRECTION_URL = "Redirection URL"
    static FORM = "Form"
    static EDIT = "Edit"
    static DPC_EXTRA="DPCextra"
    static DPC_EXTRA_LIST="DPC Extra List"
}


export class claimType {
    static QUIZ = "Quiz"
    static FORM = "Form"
    static REDIRECTION_URL = "Redirection URL"
}