import { createSlice } from "@reduxjs/toolkit";
import { updateProductAPI } from "../../services/updateProduct";

const data = {
  isLoading: false,
  error: "",
  message: "",
  data: null,
};

const updateProductSlice = createSlice({
  name: "updateProductSlice",
  initialState: data,
  reducers: {
    updateProductInfo(state) {
      state.isLoading = true;
    },
    updateProductInfoSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.message = "";
    },
    updateProductInfoFailure(state, action) {
      state.isLoading = false;
      state.message = action.payload;
      state.data = null;
    },
    updateProductInfoReset(state) {
      state.isLoading = false;
      state.message = "";
      state.data = null;
      state.error = "";
    },
  },
});

export const updateProductHandler = (data) => async (dispatch) => {
  try {
    dispatch(updateProductAction.updateProductInfo());
    const response = await updateProductAPI(data);
    dispatch(updateProductAction.updateProductInfoSuccess(response));
  } catch (e) {
    dispatch(updateProductAction.updateProductInfoFailure(e));
  }
};
export default updateProductSlice.reducer;
export const updateProductAction = updateProductSlice.actions;
