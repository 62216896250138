import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../common/CustomBreadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { BrandChangeModal } from '../../components/BrandChangeModal/BrandChangeModal';
import { getProductFeatureContentHandler } from '../../redux/action/getProductFeatureContentSlice';
import Loader from '../../components/Loder/loader';
import {
  addProductFeatureAction,
  addProductFeaturesHandler,
} from '../../redux/action/addProductFeaturesSlice';
import CoiAllState from '../../components/COI/CoiAllState';
import { getAllStatesHandler } from '../../redux/action/getAllStates';
import CoiStateWise from '../../components/COI/CoiStateWise';
import { BreadCrum } from '../../common';
import { getGroupProductFeatureContentHandler } from '../../redux/action/getGroupProductFeatureContent';
import { useCommonMessage } from '../../common/CommonMessage';
import CoiAllStateGroup from '../../components/COI/CoiAllStateGroup';
import {
  updateGroupProductFeatureAction,
  updateGroupProductFeaturesHandler,
} from '../../redux/action/updateGroupProductFeature';
import CoiStateWiseGroup from '../../components/COI/coiStateWiseGroup';
import { fileUploadAction } from '../../redux/action/fileUpload';
import { getItem, removeItem } from '../../common/localStorage';

const InsuranceCertification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messageApi = useCommonMessage();
  const [showStateWiseEnglishPdf, setShowStateWiseEnglishPdf] = useState();
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const [checkBoxModal, setCheckBoxModal] = useState({ isShow: false, key: "" });
  const [getAllStatesData, setGetAllStatesData] = useState([]);
  const [deleteUploadedFile, setDeleteUploadedFile] = useState('');
  const [manageSwitch, setManageSwitch] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [getEnglishFileUpload, setGetEnglishFileUpload] = useState({});
  const [getSpanishFileUpload, setGetSpanishFileUpload] = useState({});
  const [getGroupEnglishFileUpload, setGetGroupEnglishFileUpload] = useState(
    {}
  );
  const [getGroupSpanishFileUpload, setGetGroupSpanishFileUpload] = useState(
    {}
  );

  const [enableAddBtn, setEnableAddBtn] = useState(false);
  const [manageCheckBox, setManageCheckBox] = useState([]);
  const { state } = useLocation();

  const refFeatureId = getItem('refFeatureId')
  const productId = getItem('productId')

  const fileUploadDataSelector = useSelector((state) => state?.fileUploadData);
  const addProductFeaturesSelector = useSelector(
    (state) => state?.addProductFeatures
  );
  const updateGroupProductSelector = useSelector(
    (state) => state?.updateGroupProductFeatureMain
  );
  const getProductFeatureSelector = useSelector(
    (state) => state?.getProductFeatureContentMain
  );
  const getAllStatesSelector = useSelector((state) => state?.getAllStates);
  const getGroupProductFeatureContent = useSelector(
    (state) => state?.getGroupProductFeatureContent
  );

  let getFeatureFromGroup = getGroupProductFeatureContent?.data?.data;

  useEffect(() => {
    if (!state?.fromGroupData?.id) {
      if (addProductFeaturesSelector?.data?.data) {
        messageApi.open({
          type: 'success',
          content: addProductFeaturesSelector?.data?.message,
        });
        setEnableAddBtn(true);
        dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
        let payload1 = {
          productId: state?.productId || state?.item?.productId,
          refFeatureId: state?.refFeatureId || state?.item?.refFeatureId,
        };
        dispatch(getProductFeatureContentHandler(payload1));
      }
    } else {
      if (updateGroupProductSelector?.data?.data) {
        messageApi.open({
          type: 'success',
          content: updateGroupProductSelector?.data?.message,
        });
        setEnableAddBtn(true);
        dispatch(
          updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset()
        );
        let payload1 = {
          groupProductFeatureId: state?.fromGroupData?.id,
        };
        dispatch(getGroupProductFeatureContentHandler(payload1));
      }
    }
  }, [addProductFeaturesSelector, updateGroupProductSelector, state]);

  // When page will render the this useffect will call
  useEffect(() => {
    if (state?.fromGroupData?.id) {
      let payload1 = {
        groupProductFeatureId: state?.fromGroupData?.id,
      };
      dispatch(getGroupProductFeatureContentHandler(payload1));
    } else if (!state?.fromGroupData) {
      let payload = {
        productId: state?.productId || state?.item?.productId,
        refFeatureId: state?.refFeatureId || state?.item?.refFeatureId,
      };
      dispatch(getProductFeatureContentHandler(payload));
    }
  }, [state]);

  useEffect(() => {
    // Get All states API Handler
    if (selectedCheckbox === 'stateWise') {
      dispatch(getAllStatesHandler());
    }
  }, [selectedCheckbox]);

  useEffect(() => {
    if (getAllStatesSelector?.data?.data) {
      let data = getAllStatesSelector?.data?.data?.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      setGetAllStatesData(data);
    }
  }, [getAllStatesSelector]);

  useEffect(() => {
    if (!state?.fromGroupData?.id) {
      // When Page render then check all state or state wise is checked or not From Product features
      const handleCheckEnglish =
        manageSwitch === false &&
        getProductFeatureSelector?.data?.data?.content?.english?.coi?.state;
      const handleCheckSpanish =
        manageSwitch === false &&
        getProductFeatureSelector?.data?.data?.content?.spanish?.coi?.state;
      for (let index = 0; index < handleCheckEnglish?.length; index++) {
        const element = handleCheckEnglish[index];
        setManageCheckBox(element);
      }
      for (let index = 0; index < handleCheckSpanish?.length; index++) {
        const element = handleCheckSpanish[index];
        setManageCheckBox(element);
      }
    } else {
      // When Page render then check all state or state wise is checked or not From Group Features
      const handleCheckEnglish =
        manageSwitch === false &&
        getGroupProductFeatureContent?.data?.data?.content?.english?.coi?.state;
      const handleCheckSpanish =
        manageSwitch === false &&
        getGroupProductFeatureContent?.data?.data?.content?.spanish?.coi?.state;

      for (let index = 0; index < handleCheckEnglish?.length; index++) {
        const element = handleCheckEnglish[index];
        setManageCheckBox(element);
      }
      for (let index = 0; index < handleCheckSpanish?.length; index++) {
        const element = handleCheckSpanish[index];
        setManageCheckBox(element);
      }
    }
  }, [getProductFeatureSelector, getGroupProductFeatureContent]);

  const handleCheckboxChange = (checkbox) => {
    if (
      (getProductFeatureSelector?.data?.data?.content?.english?.coi?.state
        ?.length > 0 ||
        getProductFeatureSelector?.data?.data?.content?.spanish?.coi?.state
          ?.length > 0) ||
      (getGroupProductFeatureContent?.data?.data?.content?.english?.coi?.state
        ?.length > 0 ||
        getGroupProductFeatureContent?.data?.data?.content?.spanish?.coi?.state
          ?.length > 0)
    ) {
      setCheckBoxModal({ isShow: true, key: checkbox });
      dispatch(fileUploadAction.fileUploadInfoReset())
    } else {
      setSelectedCheckbox(checkbox);
      dispatch(fileUploadAction.fileUploadInfoReset())

    }
  };

  const handleModalYes = () => {

    if (getProductFeatureSelector?.data?.data?.content) {
      const englishData =
        getProductFeatureSelector?.data?.data?.content?.english?.coi?.state
          ?.length > 0;
      const spanishData =
        getProductFeatureSelector?.data?.data?.content?.spanish?.coi?.state
          ?.length > 0;
      if (checkBoxModal?.key === "allStates" && (englishData || spanishData)) {
        let payload = {
          productId: state?.productId || state?.item?.productId || Number(productId),
          featureId: state?.refFeatureId || state?.item?.refFeatureId || Number(refFeatureId),
          content: {
            coi: false,
            english: {
              coi: {
                state: [],
              },
            },
            spanish: {
              coi: {
                state: [],
              },
            },
            isAllState: false,
          },
        };
        setCheckBoxModal({ isShow: true, key: "stateWise" });
        dispatch(addProductFeaturesHandler(payload));
        setSelectedCheckbox(checkBoxModal?.key);
      } else if (
        checkBoxModal?.key === 'stateWise' &&
        (englishData || spanishData)
      ) {
        let payload = {
          productId: state?.productId || state?.item?.productId || Number(productId),
          featureId: state?.refFeatureId || state?.item?.refFeatureId || Number(refFeatureId),
          content: {
            coi: false,
            isAllState: true,
            english: {
              coi: {
                state: [],
              },
            },
            spanish: {
              coi: {
                state: [],
              },
            },
          },
        };
        setCheckBoxModal({ isShow: true, key: "stateWise" });
        dispatch(addProductFeaturesHandler(payload));
        setSelectedCheckbox(checkBoxModal?.key);
      }
    }
    // group functionality

    if (getGroupProductFeatureContent?.data?.data?.content) {
      const englishDataGroup =
        getGroupProductFeatureContent?.data?.data?.content?.english?.coi?.state
          ?.length > 0;
      const spanishDataGroup =
        getGroupProductFeatureContent?.data?.data?.content?.spanish?.coi?.state
          ?.length > 0;
      if (
        checkBoxModal?.key === "allStates" &&
        (englishDataGroup || spanishDataGroup)
      ) {
        let payload = {
          id: getGroupProductFeatureContent?.data?.data?.id,
          content: {
            coi: true,
            english: {
              coi: {
                state: [],
              },
            },
            spanish: {
              coi: {
                state: [],
              },
            },
            isAllState: false,
          },
        };
        setCheckBoxModal({ isShow: true, key: "stateWise" });
        dispatch(updateGroupProductFeaturesHandler(payload));
        setSelectedCheckbox(checkBoxModal?.key);
      } else if (
        checkBoxModal?.key === 'stateWise' &&
        (englishDataGroup || spanishDataGroup)
      ) {
        let payload = {
          id: getGroupProductFeatureContent?.data?.data?.id,
          content: {
            coi: false,
            isAllState: true,
            english: {
              coi: {
                state: [],
              },
            },
            spanish: {
              coi: {
                state: [],
              },
            },
          },
        };
        setCheckBoxModal({ isShow: true, key: "stateWise" });
        dispatch(updateGroupProductFeaturesHandler(payload));
        setSelectedCheckbox(checkBoxModal?.key);
      }
    }
    setCheckBoxModal({ isShow: false, key: "" });
    setManageCheckBox([]);
  };

  const handleCancel = () => {
    setAddProductFeatureModal(false);
    setCheckBoxModal({ isShow: false, key: "" });
  };

  useEffect(() => {
    if (addProductFeatureModal === false) {
      if (
        selectedCheckbox !== 'stateWise' &&
        selectedCheckbox !== 'allStates'
      ) {
        if (manageCheckBox?.stateName === true) {
          setSelectedCheckbox('allStates');
        } else if (manageCheckBox?.stateName?.length > 0) {
          setSelectedCheckbox('stateWise');
        } else {
          setSelectedCheckbox('');
        }
      }
    }
  }, [addProductFeatureModal, manageCheckBox, selectedCheckbox]);

  // Product Wise and Group wise
  useEffect(() => {
    if (
      !state?.fromGroupData?.id &&
      getProductFeatureSelector?.data?.data?.content?.isAllState === true
    ) {
      //Product Wise
      const englishStates =
        getProductFeatureSelector?.data?.data?.content?.english?.coi?.state ||
        [];
      const spanishStates =
        getProductFeatureSelector?.data?.data?.content?.spanish?.coi?.state ||
        [];

      if (spanishStates?.length > 0) {
        for (let index = 0; index < spanishStates?.length; index++) {
          const element = spanishStates[index];
          if (element?.url) {
            setGetSpanishFileUpload(element);
          }
        }
      } else {
        setGetSpanishFileUpload({});
      }
      if (englishStates?.length > 0) {
        for (let index = 0; index < englishStates?.length; index++) {
          const element = englishStates[index];
          if (element?.url) {
            setGetEnglishFileUpload(element);
          }
        }
      } else {
        setGetEnglishFileUpload({});
      }
    } else {
      //Group wise
      const englishStates =
        getGroupProductFeatureContent?.data?.data?.content?.english?.coi
          ?.state ?? [];
      const spanishStates =
        getGroupProductFeatureContent?.data?.data?.content?.spanish?.coi
          ?.state ?? [];

      if (spanishStates?.length > 0) {
        for (let index = 0; index < spanishStates?.length; index++) {
          const element = spanishStates[index];
          if (element?.url) {
            setGetGroupSpanishFileUpload(element);
          }
        }
      } else {
        setGetGroupSpanishFileUpload({});
      }
      if (englishStates?.length > 0) {
        for (let index = 0; index < englishStates?.length; index++) {
          const element = englishStates[index];
          if (element?.url) {
            setGetGroupEnglishFileUpload(element);
          }
        }
      } else {
        setGetGroupEnglishFileUpload({});
      }
    }
  }, [getProductFeatureSelector, state, getGroupProductFeatureContent]);

  return (
    <>
      {(fileUploadDataSelector?.isLoading === true ||
        addProductFeaturesSelector?.isLoading === true ||
        getProductFeatureSelector?.isLoading === true ||
        getGroupProductFeatureContent?.isLoading === true ||
        updateGroupProductSelector?.isLoading === true) && (
          <Loader loaderTransForm="loaderTransForm" />
        )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: getFeatureFromGroup?.groupLevelModification
                  ? BreadCrum.GROUPS
                  : BreadCrum.PRODUCTS,
                onClick: () => {
                  navigate(
                    getFeatureFromGroup?.groupLevelModification
                      ? '/groups'
                      : '/dashboard'
                  );
                  removeItem('productId');
                },
              },

              {
                text:
                  state?.manageProduct ||
                    getFeatureFromGroup?.groupLevelModification
                    ? BreadCrum.MANAGE_PRODUCTS
                    : BreadCrum.ADD_PRODUCT,
                onClick: () => {
                  const path = state?.manageProduct
                    ? '/edit-product'
                    : getFeatureFromGroup?.groupLevelModification
                      ? '/edit-group-product'
                      : '/addproduct';
                  const navigationState = {
                    productId: state?.productId,
                    groupCode: state?.fromGroupData?.groupId,
                  };
                  navigate(path, { state: navigationState });
                },
              },
              ...(getFeatureFromGroup?.groupLevelModification &&
                getFeatureFromGroup?.productName
                ? [{ text: getFeatureFromGroup.productName }]
                : []),
              { text: BreadCrum.CERTIFICATE_OF_INSURANCE, active: true },
            ]}
          />
          <div className="table-padding mb-30">
            <div className="headtitle d-block">
              <p className="mb-3 heedprodct">Certificate of Insurance</p>
              <hr />
              <p className="ptext">
                How would you like to add Certificate of Insurance?
              </p>
              <div className="d-md-flex">
                <div className="form-check me-30 p-0">
                  <Checkbox
                    checked={selectedCheckbox === 'stateWise'}
                    onChange={() => handleCheckboxChange('stateWise')}
                    disabled={
                      (getProductFeatureSelector?.data?.data?.content
                        ?.isAllState === false &&
                        (getProductFeatureSelector?.data?.data?.content?.english
                          ?.coi?.state?.length > 0 ||
                          getProductFeatureSelector?.data?.data?.content?.spanish
                            ?.coi?.state?.length > 0)) ||

                      (getGroupProductFeatureContent?.data?.data?.content
                        ?.isAllState === false &&
                        (getGroupProductFeatureContent?.data?.data?.content?.english
                          ?.coi?.state?.length > 0 ||
                          getGroupProductFeatureContent?.data?.data?.content?.spanish
                            ?.coi?.state?.length > 0))
                    }
                  >
                    State wise
                  </Checkbox>
                </div>
                <div className="form-check me-30 p-0">
                  <Checkbox
                    checked={selectedCheckbox === 'allStates'}
                    onChange={() => handleCheckboxChange('allStates')}
                    disabled={
                      (getProductFeatureSelector?.data?.data?.content
                        ?.isAllState === true &&
                        (getProductFeatureSelector?.data?.data?.content?.english
                          ?.coi?.state?.length > 0 ||
                          getProductFeatureSelector?.data?.data?.content?.spanish
                            ?.coi?.state?.length > 0)) ||
                      (getGroupProductFeatureContent?.data?.data?.content
                        ?.isAllState === true &&
                        (getGroupProductFeatureContent?.data?.data?.content?.english
                          ?.coi?.state?.length > 0 ||
                          getGroupProductFeatureContent?.data?.data?.content?.spanish
                            ?.coi?.state?.length > 0))
                    }
                  >
                    All States
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
          {selectedCheckbox === 'allStates' && !state?.fromGroupData?.id ? (
            <CoiAllState
              fileUploadDataSelector={fileUploadDataSelector}
              selectedCheckbox={selectedCheckbox}
              setSelectedCheckbox={setSelectedCheckbox}
              state={state}
              manageSwitch={manageSwitch}
              setManageSwitch={setManageSwitch}
              getProductFeatureSelector={getProductFeatureSelector}
              getEnglishFileUpload={getEnglishFileUpload}
              getSpanishFileUpload={getSpanishFileUpload}
              
            />
          ) : (
            <>
              {/* From Groups Components */}
              {selectedCheckbox === 'allStates' && state?.fromGroupData?.id && (
                <CoiAllStateGroup
                  fileUploadDataSelector={fileUploadDataSelector}
                  selectedCheckbox={selectedCheckbox}
                  setSelectedCheckbox={setSelectedCheckbox}
                  state={state}
                  manageSwitch={manageSwitch}
                  setManageSwitch={setManageSwitch}
                  getProductFeatureSelector={getProductFeatureSelector}
                  getGroupEnglishFileUpload={getGroupEnglishFileUpload}
                  getGroupSpanishFileUpload={getGroupSpanishFileUpload}
                  getGroupProductFeatureContent={getGroupProductFeatureContent}
                />
              )}
            </>
          )}
          {/* form product */}
          {selectedCheckbox === 'stateWise' && !state?.fromGroupData?.id ? (
            <CoiStateWise
              fileUploadDataSelector={fileUploadDataSelector}
              selectedCheckbox={selectedCheckbox}
              setSelectedCheckbox={setSelectedCheckbox}
              state={state}
              manageSwitch={manageSwitch}
              setManageSwitch={setManageSwitch}
              getProductFeatureSelector={getProductFeatureSelector}
              getEnglishFileUpload={getEnglishFileUpload}
              getSpanishFileUpload={getSpanishFileUpload}
              getAllStatesData={getAllStatesData}
              addProductFeaturesSelector={addProductFeaturesSelector}
              enableAddBtn={enableAddBtn}
              setGetAllStatesData={setGetAllStatesData}
            />
          ) : (
            <>
              {/* From Groups Components */}
              {selectedCheckbox === 'stateWise' && state?.fromGroupData?.id && (
                <CoiStateWiseGroup
                  fileUploadDataSelector={fileUploadDataSelector}
                  selectedCheckbox={selectedCheckbox}
                  setSelectedCheckbox={setSelectedCheckbox}
                  state={state}
                  manageSwitch={manageSwitch}
                  setManageSwitch={setManageSwitch}
                  getProductFeatureSelector={getProductFeatureSelector}
                  getGroupEnglishFileUpload={getGroupEnglishFileUpload}
                  getGroupSpanishFileUpload={getGroupSpanishFileUpload}
                  getAllStatesData={getAllStatesData}
                />
              )}
            </>
          )}
        </div>
        {addProductFeatureModal && (
          <BrandChangeModal
            addProductFeatureModal={addProductFeatureModal}
            setShowStateWiseEnglishPdf={setShowStateWiseEnglishPdf}
            showStateWiseEnglishPdf={showStateWiseEnglishPdf}
            handleCancel={handleCancel}
            handleModalYes={handleModalYes}
            deleteUploadedFile={deleteUploadedFile}
          />
        )}
        {checkBoxModal?.isShow && (
          <BrandChangeModal
            addProductFeatureModal={checkBoxModal?.isShow}
            setShowStateWiseEnglishPdf={setShowStateWiseEnglishPdf}
            showStateWiseEnglishPdf={showStateWiseEnglishPdf}
            handleCancel={handleCancel}
            handleModalYes={handleModalYes}
            deleteUploadedFile={deleteUploadedFile}
          />
        )}
      </div>
    </>
  );
};

export default InsuranceCertification;
