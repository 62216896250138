import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import addbtn from "../../assets/Add_Button.svg";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import SelectInput from "../../common/SelectInput";
import { Checkbox } from "antd";
import ansbtn from "../../assets/ansbtn.svg";
import copybtn from "../../assets/Copy.svg";
import deletebtn from "../../assets/Delete.svg";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import { v4 as uuidv4 } from "uuid";
import {
  addProductFeatureAction,
  addProductFeaturesHandler,
} from "../../redux/action/addProductFeaturesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useCommonMessage } from "../../common/CommonMessage";
import { getProductFeaturesHandler } from "../../redux/action/getproductFeaturesSlice";
import { handleKeyPressSpace, handlePaste } from "../../common/CommonFunctions";
import { createPayload } from "../../components/Claim/claimPayload";
import { validationSchemaAddClaimForm } from "../../common/Validations";
import { OPTIONS, questionOption } from "./claimFormUtils";
import Loader from "../Loder/loader";
import { getItem, removeItem } from "../../common/localStorage";

const ClaimForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const messageApi = useCommonMessage();
  const dispatch = useDispatch();
  const [selectedQuestionType, setSelectedQuestionType] = useState("");
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const [deleteUploadedFile, setDeleteUploadedFile] = useState("");
  const [initialValueBoth, setInitialValueBoth] = useState([]);
  const [allFormData, setAllFormData] = useState([]);
  const [handleDeleteDataIndex, setHandleDeleteDataIndex] = useState();
  const [answerKeyVisibility, setAnswerKeyVisibility] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showAnswerKey, setShowAnswerKey] = useState(false);

  const getProductId = getItem("productId");
  const refFeatureId = getItem("refFeatureId");

  const getProductFeaturesSelector = useSelector(
    (state) => state?.getProductFeatures
  );

  const addProductFeaturesSelector = useSelector(
    (state) => state?.addProductFeatures
  );
  const handleFormSubmit = (values) => {
    const invalidQuestions = values?.questions.filter(
      (item) =>
        item?.selectedQuestionType === "radio" &&
        (!item?.correctAnswer || item?.correctAnswer?.length === 0)
    );

    if (invalidQuestions?.length > 0) {
      messageApi.open({
        type: "warning",
        content: "Please Add Answer Key..!",
      });
    }

    // Old Record
    const existingClaims =
      allFormData?.content?.claim?.map((item) => {
        return {
          formData: item?.formData?.map((item, i) => {
            return {
              questionType: item?.questionType,
              questionText: item?.questionText,
              answerChoice: item?.answerChoice,
              questionInfo: "",
              userAnswer: "",
              numberOfChoice: Number(item?.numberOfChoice),
              correctAnswer: item?.correctAnswer,
            };
          }),
          id: item?.id,
          language: item?.language,
          claimName: item?.claimName,
          claimType: item?.claimType,
          claimStatus: item?.claimStatus || "ACTIVE",
          setQuestionsCount: item?.formData?.length,
          setAttemptedQuestionsCount: 0,
          formTitle: item?.formTitle,
          formDescription: item?.formDescription,
          urlData: item?.urlData,
          quizData: item?.quizData,
        };
      }) || [];
    // New Claim form Addes functionality

    const newClaim = {
      formData: values?.questions.map((item, i) => {
        const correctAnswerIncluded = item?.options
          ?.slice(0, item?.numberOfChoice)
          ?.includes(item?.correctAnswer);
        return {
          questionType: item?.selectedQuestionType,
          questionText: item?.questionText,
          // answerChoice: item?.options?.slice(0, item?.numberOfChoice),
          answerChoice: item?.options
            ?.slice(0, item?.numberOfChoice)
            ?.map((option) => option),
          questionInfo: "",
          userAnswer: "",
          numberOfChoice: Number(item?.numberOfChoice),
          correctAnswer: correctAnswerIncluded ? item?.correctAnswer : "",
        };
      }),
      id: uuidv4(),
      language: state?.handleSwitch === false ? "en" : "es",
      claimName: state?.values?.claimName || state?.values?.claimNameSpanish,
      claimType: state?.values?.claimType || state?.values?.claimTypeSpanish,
      claimStatus: "ACTIVE",
      setQuestionsCount: values?.questions?.length,
      setAttemptedQuestionsCount: 0,
      formTitle: values?.title,
      formDescription: values?.description,
      urlData: "",
      quizData: [],
    };
    const matchSeqId = allFormData?.content?.claim?.findIndex(
      (item) => item?.id === state?.claimData?.id
    );
    if (matchSeqId > -1) {
      existingClaims[matchSeqId] = newClaim;
    } else {
      existingClaims.push(newClaim);
    }

    if (!invalidQuestions?.length > 0) {
      let payload1 = createPayload(existingClaims);
      dispatch(addProductFeaturesHandler(payload1));
      setTimeout(() => {
        navigate("/claim");
      }, 1000);
    }
  };

  // set initial value from api

  useEffect(() => {
    if (
      allFormData?.content?.claim?.[0]?.formData &&
      state?.claimData?.fromClaim !== null
    ) {
      const initialData = allFormData?.content?.claim?.[0]?.formData?.map(
        (item) => ({
          selectedQuestionType: item?.questionType,
          questionText: item?.questionText,
          options: item?.answerChoice,
          numberOfChoice: item?.numberOfChoice,
          correctAnswer: item?.correctAnswer,
        })
      );
      setInitialValueBoth(initialData);
    }
  }, [allFormData, state]);

  useEffect(() => {
    const productFeatures =
      getProductFeaturesSelector?.data?.data?.productFeature;
    if (productFeatures?.length > 0) {
      const claimFeatures = productFeatures?.filter(
        (feature) => feature?.content?.type === "claim"
      );
      if (claimFeatures?.length > 0) {
        for (let index = 0; index < claimFeatures?.length; index++) {
          const element = claimFeatures[index];
          setAllFormData(element);
        }
      }
    }
  }, [getProductFeaturesSelector]);

  const getProductFeatureFunction = () => {
    if (Number(refFeatureId)) {
      let payload = {
        productId: Number(getProductId),
        refFeatureId: Number(refFeatureId),
      };
      dispatch(getProductFeaturesHandler(payload));
    }
  };

  useEffect(() => {
    getProductFeatureFunction();
  }, [state]);

  useEffect(() => {
    if (addProductFeaturesSelector?.data?.statusCode === 200) {
      messageApi.open({
        type: "success",
        content: addProductFeaturesSelector?.data?.message,
      });
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
      getProductFeatureFunction();
    } else if (addProductFeaturesSelector?.message) {
      messageApi.open({
        type: "error",
        content: addProductFeaturesSelector?.message,
      });
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
    }
  }, [addProductFeaturesSelector]);

  const handleCancel = () => {
    setAddProductFeatureModal(false);
  };

  const handleModalYes = () => {
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteQuestion");
    handleDeleteDataIndex.remove(handleDeleteDataIndex?.index);
  };

  const deleteNotificationHandlers = () => {
    setAddProductFeatureModal(true);
    setDeleteUploadedFile("DeleteQuestion");
  };

  const toggleAnswerKeyVisibility = (index) => {
    setAnswerKeyVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  const handleOptionClick = (questionIndex, optionIndex) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[questionIndex] = optionIndex;
      return newSelectedOptions;
    });
  };

  return (
    <div className="rightside">
      <div className="rightcontent">
        <p>
          <strong>Form</strong>
        </p>
        <CustomBreadcrumb
          items={[
            { text: BreadCrum.HOME },
            {
              text: BreadCrum.PRODUCTS,
              onClick: () => {
                navigate("/dashboard");
                removeItem("productId");
                dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
              },
            },
            {
              text: BreadCrum.MANAGE_PRODUCTS,
              onClick: () => navigate("/edit-product"),
            },
            {
              text: BreadCrum.CLAIM_LIST,
              onClick: () => navigate("/claim"),
            },
            {
              text: BreadCrum.ADD_CLAIM,
              onClick: () => navigate("/Add-claim"),
            },
            { text: BreadCrum.FORM, active: true },
            // { text: BreadCrum.ADD_CLAIM, active: true },
          ]}
        />
        <Formik
          enableReinitialize
          initialValues={{
            title: "",
            description: "",
            questions: [
              {
                question: "",
                options: ["", ""],
                selectedQuestionType: "",
                questionText: "",
                numberOfChoice: "02",
                correctAnswer: "",
              },
            ],
          }}
          validationSchema={validationSchemaAddClaimForm}
          onSubmit={(values) => {
            handleFormSubmit(values);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            errors,
            touched,
          }) => (
            <Form>
              {addProductFeaturesSelector?.isLoading === true && (
                <Loader loaderTransForm="loaderTransForm" />
              )}
              <div className="table-padding mb-40 ">
                {/* chip set */}
                <div className="headtitle d-block">
                  <div className="d-flex justify-content-between align-items-center d-4grid">
                    <div>
                      <div className="productname mb-10">Claim Name</div>
                      <div className="producttitle ">
                        {state?.values?.claimName ||
                          state?.values?.claimNameSpanish}
                      </div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Claim Type</div>
                      <div className="producttitle ">
                        {state?.values?.claimType ||
                          state?.values?.claimTypeSpanish}
                      </div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Language</div>
                      <div className="producttitle ">
                        {state?.handleSwitch === false ? "English" : " Spanish"}
                      </div>
                    </div>
                    <div className="verticalline mx-100"></div>
                    <div className="">
                      <div className="productname mb-10">Claim Status</div>
                      <div className="producttitle ">ACTIVE</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sectionpadding mb-30 hoversection">
                <div className="login-form">
                  <div className="headtitle p-0">
                    <p className="mb-0 heedprodct">
                      {state?.handleSwitch === false
                        ? "Form - For English"
                        : "Form - For Spanish"}
                    </p>
                  </div>
                  <hr />
                  <label className="mb-2">Form Title*</label>
                  <div className="mb-25">
                    <Field
                      id="title"
                      name="title"
                      type="text"
                      placeholder="Form Title"
                      className="w-100  position-relative form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // onPaste={handlePaste}
                      onKeyPress={handleKeyPressSpace}
                      maxLength={200}
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="error"
                    />
                    {values?.title?.length > 0 && (
                      <div className="character-count text-end">
                        {values?.title?.length} / 200 characters
                      </div>
                    )}
                  </div>
                  <div className="mb-25 position-relative ">
                    <label className="mb-2">Form Description*</label>
                    <Field
                      id="description"
                      name="description"
                      as="textarea"
                      rows={5}
                      placeholder="Form Description"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // onPaste={handlePaste}
                      onKeyPress={handleKeyPressSpace}
                      maxLength={65000}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="error"
                    />
                    {values?.description?.length > 0 && (
                      <div className="character-count text-end">
                        {values?.description?.length} / 65000 characters
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <FieldArray name="questions">
                  {({ push, remove, form }) => (
                    <>
                      {values?.questions?.map((question, index) => (
                        <>
                          <div className="sectionpadding  mb-30">
                            <div className="d-xxl-flex mb-30 hidesection">
                              <div
                                className=" pe-xxl-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0 hidesection"
                                controlId="formBasicEmail"
                              >
                                <label className="mb-2">
                                  Select Question Type*
                                </label>
                                <SelectInput
                                  value={questionOption.find(
                                    (option) =>
                                      option?.value ===
                                      question?.selectedQuestionType
                                  )}
                                  onBlur={handleBlur}
                                  options={questionOption}
                                  placeholder={"Select Question Type"}
                                  onChange={(selectedOption) => {
                                    form.setFieldValue(
                                      `questions[${index}].selectedQuestionType`,
                                      selectedOption
                                    );
                                    form.setFieldValue(
                                      `questions[${index}].questionText`,
                                      ""
                                    );
                                    setSelectedQuestionType(selectedOption);
                                  }}
                                />
                                <ErrorMessage
                                  name={`questions[${index}].selectedQuestionType`}
                                  component="div"
                                  className="error"
                                />
                              </div>
                              {(question?.selectedQuestionType === "radio" ||
                                question?.selectedQuestionType ===
                                  "dropDownSingle" ||
                                question?.selectedQuestionType ===
                                  "dropDownMulti" ||
                                question?.selectedQuestionType ===
                                  "checkBox") && (
                                <div
                                  className=" pe-xxl-2 hidesection position-relative me-xxl-2 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <label className="mb-2">No. of Choices</label>
                                  <SelectInput
                                    value={
                                      Number(question?.numberOfChoice) || 2
                                    }
                                    options={OPTIONS}
                                    onChange={(selectedOption) => {
                                      form.setFieldValue(
                                        `questions[${index}].numberOfChoice`,
                                        selectedOption
                                      );
                                      form.setFieldValue(
                                        `questions[${index}].options`,
                                        Array(Number(selectedOption)).fill("")
                                      );
                                      form.setFieldValue(
                                        `questions[${index}].correctAnswer`,
                                        ""
                                      );
                                      setShowAnswerKey(false);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            {question?.selectedQuestionType === "radio" && (
                              <div>
                                <div
                                  controlId="formBasicEmail"
                                  className="login-form mb-30"
                                >
                                  <label className="mb-2">Question*</label>
                                  <Field
                                    className="selectdisplayed form-control"
                                    type="text"
                                    id="questionText"
                                    placeholder="Untitled Question"
                                    name={`questions[${index}].questionText`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      form.setFieldValue(
                                        `questions[${index}].${name}`,
                                        value
                                      );
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // onPaste={handlePaste}
                                    onKeyPress={handleKeyPressSpace}
                                    maxLength={150}
                                  />
                                  <ErrorMessage
                                    name={`questions[${index}].questionText`}
                                    component="div"
                                    className="error"
                                  />
                                </div>

                                <div
                                  className=" pe-2 position-relative optionselection hidesection"
                                  controlId="formBasicEmail"
                                >
                                  <label className="mb-2">Options*</label>
                                  <ul className="p-0">
                                    {[
                                      ...Array(
                                        Number(question?.numberOfChoice || 2)
                                      ),
                                    ]?.map((_, indexs) => (
                                      <li key={index}>
                                        <Checkbox className="">
                                          <div className="checkradiobtn d-inline-block"></div>
                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                            <Field
                                              type="text"
                                              placeholder={`Option ${
                                                indexs + 1
                                              }`}
                                              name={`questions[${index}].options[${indexs}]`}
                                              onChange={(e) => {
                                                const newOptions = [
                                                  ...(question?.options || []),
                                                ];
                                                newOptions[indexs] =
                                                  e.target.value;

                                                form.setFieldValue(
                                                  `questions[${index}].options`,
                                                  newOptions
                                                );
                                                form.setFieldValue(
                                                  `questions[${index}].correctAnswer`,
                                                  ""
                                                );
                                                setShowAnswerKey(false);
                                                setSelectedOptions([]);
                                              }}
                                              onBlur={handleBlur}
                                              // onPaste={handlePaste}
                                              onKeyPress={handleKeyPressSpace}
                                            />
                                          </div>
                                        </Checkbox>
                                      </li>
                                    ))}
                                  </ul>
                                  {touched?.questions?.[index]?.options && (
                                    <div className="error">
                                      {Array.isArray(
                                        errors?.questions?.[index]?.options
                                      )
                                        ? "Options are required"
                                        : errors?.questions?.[index]?.options}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {question?.selectedQuestionType === "checkBox" && (
                              <div>
                                <div
                                  controlId="formBasicEmail"
                                  className="login-form mb-30"
                                >
                                  <label className="mb-2">Question* </label>
                                  <Field
                                    className=" selectdisplayed form-control"
                                    type="text"
                                    placeholder="Untitled Question"
                                    name={`questions[${index}].questionText`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      form.setFieldValue(
                                        `questions[${index}].${name}`,
                                        value
                                      );
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // onPaste={handlePaste}
                                    onKeyPress={handleKeyPressSpace}
                                    maxLength={150}
                                  />
                                  <ErrorMessage
                                    name={`questions[${index}].questionText`}
                                    component="div"
                                    className="error"
                                  />
                                </div>

                                <div
                                  className=" pe-2 position-relative optionselection hidesection"
                                  controlId="formBasicEmail"
                                >
                                  <label className="mb-2">Options* </label>
                                  <ul className="p-0">
                                    {[
                                      ...Array(
                                        Number(question?.numberOfChoice) || 2
                                      ),
                                    ].map((_, indexs) => (
                                      <li key={index}>
                                        <Checkbox className="">
                                          <div className="checkradiobtn d-inline-block"></div>
                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                            <Field
                                              type="text"
                                              placeholder={`Option ${
                                                indexs + 1
                                              }`}
                                              name={`questions[${index}].options[${indexs}]`}
                                              onChange={(e) => {
                                                const newOptions = [
                                                  ...(question?.options || []),
                                                ];
                                                newOptions[indexs] =
                                                  e?.target?.value;
                                                form.setFieldValue(
                                                  `questions[${index}].options`,
                                                  newOptions
                                                );
                                                handleChange(e);
                                              }}
                                              onBlur={handleBlur}
                                              // onPaste={handlePaste}
                                              onKeyPress={handleKeyPressSpace}
                                            />
                                          </div>
                                        </Checkbox>
                                      </li>
                                    ))}
                                  </ul>
                                  {touched?.questions?.[index]?.options && (
                                    <div className="error">
                                      {Array.isArray(
                                        errors?.questions?.[index]?.options
                                      )
                                        ? "Options are required"
                                        : errors?.questions?.[index]?.options}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {question?.selectedQuestionType ===
                              "dropDownSingle" && (
                              <div>
                                <div
                                  controlId="formBasicEmail"
                                  className="login-form mb-30"
                                >
                                  <label className="mb-2">Question* </label>
                                  <Field
                                    className=" selectdisplayed form-control"
                                    type="text"
                                    placeholder="Untitled Question"
                                    id="questionText"
                                    name={`questions[${index}].questionText`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      form.setFieldValue(
                                        `questions[${index}].${name}`,
                                        value
                                      );
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // onPaste={handlePaste}
                                    onKeyPress={handleKeyPressSpace}
                                    maxLength={150}
                                  />
                                  <ErrorMessage
                                    name={`questions[${index}].questionText`}
                                    component="div"
                                    className="error"
                                  />
                                </div>

                                <div
                                  className=" pe-2 position-relative optionselection hidesection"
                                  controlId="formBasicEmail"
                                >
                                  <label className="mb-2">Options* </label>
                                  <ul className="p-0">
                                    {[
                                      ...Array(
                                        Number(question?.numberOfChoice) || 2
                                      ),
                                    ].map((_, indexs) => (
                                      <li key={index}>
                                        <Checkbox className="">
                                          <div className="checkradiobtn d-inline-block"></div>
                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                            <Field
                                              type="text"
                                              placeholder={`Option ${
                                                indexs + 1
                                              }`}
                                              name={`questions[${index}].options[${indexs}]`}
                                              onChange={(e) => {
                                                const newOptions = [
                                                  ...(question?.options || []),
                                                ];
                                                newOptions[indexs] =
                                                  e?.target?.value;
                                                form.setFieldValue(
                                                  `questions[${index}].options`,
                                                  newOptions
                                                );
                                                handleChange(e);
                                              }}
                                              onBlur={handleBlur}
                                              // onPaste={handlePaste}
                                              onKeyPress={handleKeyPressSpace}
                                            />
                                          </div>
                                        </Checkbox>
                                      </li>
                                    ))}
                                  </ul>
                                  {touched?.questions?.[index]?.options && (
                                    <div className="error">
                                      {Array.isArray(
                                        errors?.questions?.[index]?.options
                                      )
                                        ? "Options are required"
                                        : errors?.questions?.[index]?.options}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {question?.selectedQuestionType ===
                              "dropDownMulti" && (
                              <div>
                                <div
                                  controlId="formBasicEmail"
                                  className="login-form mb-30"
                                >
                                  <label className="mb-2">Question* </label>
                                  <Field
                                    className=" selectdisplayed form-control"
                                    type="text"
                                    placeholder="Untitled Question"
                                    name={`questions[${index}].questionText`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      form.setFieldValue(
                                        `questions[${index}].${name}`,
                                        value
                                      );
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // onPaste={handlePaste}
                                    onKeyPress={handleKeyPressSpace}
                                    maxLength={150}
                                  />
                                  <ErrorMessage
                                    name={`questions[${index}].questionText`}
                                    component="div"
                                    className="error"
                                  />
                                </div>

                                <div
                                  className=" pe-2 position-relative optionselection hidesection"
                                  controlId="formBasicEmail"
                                >
                                  <label className="mb-2">Options*</label>
                                  <ul className="p-0">
                                    {[
                                      ...Array(
                                        Number(question?.numberOfChoice) || 2
                                      ),
                                    ].map((_, indexs) => (
                                      <li key={index}>
                                        <Checkbox className="">
                                          <div className="checkradiobtn d-inline-block"></div>
                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                            <Field
                                              type="text"
                                              placeholder={`Option ${
                                                indexs + 1
                                              }`}
                                              name={`questions[${index}].options[${indexs}]`}
                                              onChange={(e) => {
                                                const newOptions = [
                                                  ...(question?.options || []),
                                                ];
                                                newOptions[indexs] =
                                                  e?.target?.value;
                                                form.setFieldValue(
                                                  `questions[${index}].options`,
                                                  newOptions
                                                );
                                              }}
                                              onBlur={handleBlur}
                                              // onPaste={handlePaste}
                                              onKeyPress={handleKeyPressSpace}
                                            />
                                          </div>
                                        </Checkbox>
                                      </li>
                                    ))}
                                  </ul>
                                  {touched?.questions?.[index]?.options && (
                                    <div className="error">
                                      {Array.isArray(
                                        errors?.questions?.[index]?.options
                                      )
                                        ? "Options are required"
                                        : errors?.questions?.[index]?.options}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {question?.selectedQuestionType === "textField" && (
                              <>
                                <label className="mb-2">Question*</label>
                                <div className="mb-25 login-form">
                                  <Field
                                    id="questionText"
                                    type="text"
                                    placeholder="Untitled Question"
                                    className="w-100  position-relative form-control"
                                    name={`questions[${index}].questionText`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      form.setFieldValue(
                                        `questions[${index}].${name}`,
                                        value
                                      );
                                      handleChange(e);
                                    }}
                                    onBlur={handleBlur}
                                    // onPaste={handlePaste}
                                    onKeyPress={handleKeyPressSpace}
                                    maxLength={150}
                                  />
                                </div>
                                <ErrorMessage
                                  name={`questions[${index}].questionText`}
                                  component="div"
                                  className="error"
                                />
                                <div className="mb-25 position-relative ">
                                  <label className="mb-2 login-form">
                                    Answer
                                  </label>
                                  <Field
                                    id="description"
                                    as="textarea"
                                    rows={5}
                                    placeholder="Type in your answer here..."
                                    className="form-control"
                                    name={`questions[${index}].userAnswer`}
                                    onChange={(e) => {
                                      const { name, value } = e.target;
                                      form.setFieldValue(
                                        `questions[${index}].${name}`,
                                        value
                                      );
                                      handleChange(e);
                                    }}
                                    disabled={
                                      question?.selectedQuestionType ===
                                      "textField"
                                    }
                                    onBlur={handleBlur}
                                    // onPaste={handlePaste}
                                    onKeyPress={handleKeyPressSpace}
                                  />
                                </div>
                              </>
                            )}
                            <hr className="mb-2 hidesection" />
                            <div className="w-100 d-flex justify-content-between iconsizeresponsive hidesection">
                              {question?.selectedQuestionType === "radio" &&
                                question?.options?.filter((item) => !item)
                                  .length <= 1 && (
                                  <>
                                    <div className=" d-flex align-items-center">
                                      <div
                                        className="plusicon anskey"
                                        onClick={() => {
                                          setShowAnswerKey(true);
                                          toggleAnswerKeyVisibility(index);
                                        }}
                                      >
                                        <img src={ansbtn} alt="" />
                                        <span>Answer key</span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              <div className=" d-flex align-items-center">
                                {index === values?.questions?.length - 1 && (
                                  <button
                                    className="plusicon faqIcon me-20 cursor-pointer"
                                    type="button"
                                    onClick={() =>
                                      push({
                                        question: "",
                                        options: ["", ""],
                                        selectedQuestionType: "",
                                        questionText: "",
                                        numberOfChoice: "2",
                                      })
                                    }
                                  >
                                    <img src={addbtn} alt="" />
                                  </button>
                                )}
                                <button
                                  className="plusicon faqIcon me-20 cursor-pointer"
                                  type="button"
                                  onClick={() => {
                                    const newQuestion = { ...question };
                                    push(newQuestion);
                                  }}
                                >
                                  <img src={copybtn} alt="" className="me-20" />
                                </button>
                                <div className="">
                                  {index !== 0 && (
                                    <button
                                      className="plusicon faqIcon me-20 cursor-pointer"
                                      type="button"
                                      onClick={() => {
                                        setHandleDeleteDataIndex({
                                          index,
                                          remove,
                                        });
                                        deleteNotificationHandlers();
                                      }}
                                    >
                                      <img src={deletebtn} alt="" />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {showAnswerKey &&
                            question?.options?.length > 0 &&
                            question?.selectedQuestionType === "radio" &&
                            (question?.selectedQuestionType === "radio" ||
                              selectedQuestionType === "radio") && (
                              <div>
                                {answerKeyVisibility[index] && (
                                  <div className="sectionpadding mb-30">
                                    <div className="headtitle p-0">
                                      <p className="mb-0 heedprodct">
                                        Select Correct Answer
                                      </p>
                                    </div>
                                    <hr />
                                    <div
                                      controlId="formBasicEmail"
                                      className="login-form mb-30"
                                    >
                                      <div className="">
                                        {question?.questionText}
                                      </div>
                                    </div>
                                    <div
                                      className="pe-2 position-relative optionselection hidesection"
                                      controlId="formBasicEmail"
                                    >
                                      <ul className="p-0">
                                        {[
                                          ...Array(
                                            Number(question?.numberOfChoice) ||
                                              2
                                          ),
                                        ]?.map(
                                          (_, indexs) =>
                                            question?.options[indexs] && (
                                              <li key={indexs}>
                                                <Checkbox
                                                  className={`checkbox ${
                                                    selectedOptions[index] ===
                                                      indexs ||
                                                    question?.options[
                                                      indexs
                                                    ] ===
                                                      question?.correctAnswer
                                                      ? "ansSelected"
                                                      : ""
                                                  } `}
                                                  onClick={() => {
                                                    handleOptionClick(
                                                      index,
                                                      indexs
                                                    );
                                                    form.setFieldValue(
                                                      `questions[${index}].correctAnswer`,
                                                      question?.options[indexs]
                                                    );
                                                  }}
                                                >
                                                  <div className="checkradiobtn d-inline-block"></div>
                                                  <div className="d-flex align-items-center justify-content-between w-cal">
                                                    <div
                                                      name={`questions[${index}].options[${indexs}]`}
                                                    >
                                                      {question?.options?.[
                                                        indexs
                                                      ] ||
                                                        `Option ${indexs + 1}`}
                                                    </div>
                                                  </div>
                                                </Checkbox>
                                              </li>
                                            )
                                        )}
                                      </ul>
                                    </div>
                                    <div className="text-end">
                                      <button
                                        className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
                                        onClick={() => {
                                          toggleAnswerKeyVisibility(index);
                                        }}
                                        type="button"
                                      >
                                        Done
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </>
                      ))}
                    </>
                  )}
                </FieldArray>
              </div>
              <div className="text-end">
                <button
                  className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          deleteUploadedFile={deleteUploadedFile}
        />
      )}
    </div>
  );
};

export default ClaimForm;
