import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleKeyPressSpace } from "../../common/CommonFunctions";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { useCommonMessage } from "../../common/CommonMessage";
import Loader from "../Loder/loader";
import uploadimg from "../../assets/uploadicon.svg";
import eye from "../../assets/eye.svg";
import deleteicon from "../../assets/deleteicon.svg";
import { Checkbox, message, Upload } from "antd";
import { ThemeContext } from "../../common/themeContext";
import axios from "axios";
import { API_URL } from "../../services/http";
import { fileUploadAction } from "../../redux/action/fileUpload";
import {
  dpcExtraDiscountValidation,
  dpcExtraDiscountValidationSpanish,
} from "../../common/Validations";
import { getGroupProductFeatureHandler } from "../../redux/action/getGroupProductFeature";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";
import {
  updateGroupProductFeatureAction,
  updateGroupProductFeaturesHandler,
} from "../../redux/action/updateGroupProductFeature";
import { getItem } from "../../common/localStorage";

const AddDpcDiscountGroup = () => {
  const [fileUploaded, setFileUploaded] = useState("");
  const [spanishFileUpload, setSpanishFileUpload] = useState("");
  const [loading, setLoading] = useState(false);
  const messageApi = useCommonMessage();
  const { Dragger } = Upload;
  const fileUploadDataSelector = useSelector((state) => state?.fileUploadData);

  const getAdminLoginId = getItem("adminId");
  const getProductId = getItem("productId");
  const groupId = getItem("groupId");

  const { brandId } = useContext(ThemeContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const groupProductFeatureId = getItem("groupProductFeatureId");

  const fileUploadSelector = useSelector((state) => state?.fileUploadData);
  // Group Data Selector
  const getGroupProductFeatureContentSelector = useSelector(
    (state) => state?.getGroupProductFeatureContent
  );

  const getGroupProductFeatureStateSelector = useSelector(
    (state) => state?.getGroupProductFeatureState
  );

  const updateGroupProductFeatureSelector = useSelector(
    (state) => state?.updateGroupProductFeatureMain
  );

  let fileUploadData = fileUploadDataSelector?.data?.data;

  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    showUploadList: false,
    async beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        message.error("You can only upload PDF files!");
        setFileUploaded("");
        return false;
      }
      const isSizeAccepted = file.size / 1024 / 1024 < 11;
      if (!isSizeAccepted) {
        message.error("File size exceeds 10MB limit!");
        setFileUploaded("");
        return false;
      }
      if (isPDF && isSizeAccepted) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", Number(getAdminLoginId));
        formData.append("brandId", brandId);
        formData.append(
          "path",
          `${
            "product/" +
            getGroupProductFeatureContentSelector?.data?.data?.productCode
          }/${state?.state?.item?.featureName}/${state?.selectedDiscountType}/${
            state?.manageSwitch === false ? "English" : "Spanish"
          }`
        );
        setLoading(true);
        axios
          .post(API_URL + "/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            dispatch(fileUploadAction.fileUploadInfoSuccess(response.data));
            if (state?.manageSwitch === false) {
              setFileUploaded(file?.name);
            } else {
              setSpanishFileUpload(file?.name);
            }
          })
          .catch((error) => {
            dispatch(fileUploadAction.fileUploadInfoFailure(error));
          })
          .finally(() => {
            setLoading(false);
          });
        if (state?.manageSwitch === false) {
          setFileUploaded(file?.name);
        } else {
          setSpanishFileUpload(file?.name);
        }
      }
      return false;
    },
  };
  const getGroupProductFeatureFunction = () => {
    if (Number(getProductId) && Number(groupId) && groupProductFeatureId) {
      let payload = {
        productId: Number(getProductId),
        groupId: Number(groupId),
      };
      let payload1 = {
        groupProductFeatureId: groupProductFeatureId,
      };
      dispatch(getGroupProductFeatureHandler(payload));
      dispatch(getGroupProductFeatureContentHandler(payload1));
    }
  };

  useEffect(() => {
    getGroupProductFeatureFunction();
  }, [state]);

  const handleFormSubmit = (values) => {
    const isEnglish = state?.manageSwitch === false;

    const id = Number(groupProductFeatureId);

    const isMatch =
      getGroupProductFeatureContentSelector?.data?.data?.content?.componants.some(
        (ele) =>
          ele.name?.trim().toLowerCase() ===
          state?.selectedDiscountType?.trim().toLowerCase()
      );

    // Assuming you have access to the existing data, such as:
    const existingComponents =
      getGroupProductFeatureContentSelector?.data?.data?.content?.componants ||
      [];

    // Filter components based on the match condition
    const components = existingComponents.map((item) => {
      // Only process the component if it matches the selectedDiscountType
      const matchingCondition =
        item.name?.trim().toLowerCase() ===
        state?.selectedDiscountType?.trim().toLowerCase();

      // Only modify the matched component, otherwise return it unchanged
      if (matchingCondition) {
        return {
          name: item?.name,
          english: isEnglish
            ? {
                url: values?.discountUrlEnglish || item?.english?.url,
                phone:
                  values?.phoneNumberEnglish.toString() ||
                  item?.english?.phone.toString(),
                fileUrl:
                  fileUploadSelector?.data?.data?.signedUrl ||
                  item?.english?.fileUrl,
                filePreSigned:
                  fileUploadSelector?.data?.data?.preSignedUrl ||
                  item?.english?.filePreSigned,
                fileName: fileUploaded || item?.english?.fileName,
                isValuePresent: state?.manageSwitch === false ? true : false,
                isVirtualCare: values?.isVirtualCareEnglish || item?.english?.isVirtualCare,
                virtualCare: item?.english?.virtualCare,
              }
            : {
                url: item?.english?.url || "",
                phone: item?.english?.phone.toString() || "",
                fileUrl: item?.english?.fileUrl || "",
                filePreSigned: item?.english?.filePreSigned || "",
                fileName: item?.english?.fileName || "",
                isValuePresent: item?.english?.isValuePresent,
                isVirtualCare: item?.english?.isVirtualCare,
                virtualCare: item?.english?.virtualCare,
              },
          spanish: !isEnglish
            ? {
                url: values?.discountUrlSpanish || item?.spanish?.url || "",
                phone:
                  values?.phoneNumberSpanish.toString() ||
                  item?.spanish?.phone.toString() ||
                  "",
                fileUrl: spanishFileUpload
                  ? fileUploadSelector?.data?.data?.signedUrl
                  : item?.spanish?.fileUrl,
                filePreSigned: spanishFileUpload
                  ? fileUploadSelector?.data?.data?.preSignedUrl
                  : item?.spanish?.filePreSigned,
                fileName: spanishFileUpload || item?.spanish?.fileName || "",
                isValuePresent: state?.manageSwitch === true ? true : false,
                isVirtualCare: values?.isVirtualCareSpanish|| item?.spanish?.isVirtualCare,
                virtualCare: item?.spanish?.virtualCare,
              }
            : {
                url: item?.spanish?.url || "",
                phone: item?.spanish?.phone.toString() || "",
                fileUrl: item?.spanish?.fileUrl || "",
                filePreSigned: item?.spanish?.filePreSigned || "",
                fileName: item?.spanish?.fileName || "",
                isValuePresent: item?.spanish?.isValuePresent,
                isVirtualCare: item?.spanish?.isVirtualCare,
                virtualCare: item?.spanish?.virtualCare,
              },
          status: item?.status,
          // Only update language for matching components
          language: isEnglish ? "english" : "spanish",
          isSelected: true,
          spanishName:item?.spanishName
        };
      }

      // Return unchanged component for non-matching ones
      return {
        ...item,
        english: {
          url: item?.english?.url || "",
          phone: item?.english?.phone || "",
          fileUrl: item?.english?.fileUrl || "",
          filePreSigned: item?.english?.filePreSigned || "",
          fileName: item?.english?.fileName || "",
          isValuePresent: item?.english?.isValuePresent || false,
          isVirtualCare: item?.english?.isVirtualCare,
          virtualCare: item?.english?.virtualCare,
        },
        spanish: {
          url: item?.spanish?.url || "",
          phone: item?.spanish?.phone || "",
          fileUrl: item?.spanish?.fileUrl || "",
          filePreSigned: item?.spanish?.filePreSigned || "",
          fileName: item?.spanish?.fileName || "",
          isValuePresent: item?.spanish?.isValuePresent || false,
          isVirtualCare: item?.spanish?.isVirtualCare,
          virtualCare: item?.spanish?.virtualCare,
        },
        status: item?.status,
        isSelected: item?.isSelected !== undefined ? item.isSelected : true, // New components are selected by default
        // Keep language as it is for non-matching components
        language: item.language || (isEnglish ? "english" : "spanish"),
        spanishName:item?.spanishName
      };
    });

    const payload = {
      id,
      content: {
        type: getGroupProductFeatureContentSelector?.data?.data?.content?.type,
        componants: components,
      },
    };
    dispatch(updateGroupProductFeaturesHandler(payload));
  };

  // Delete File
  const deleteFile = () => {
    setFileUploaded("");
    setSpanishFileUpload("");
    dispatch(fileUploadAction.fileUploadInfoReset());
  };

  useEffect(() => {
    if (updateGroupProductFeatureSelector?.data?.statusCode === 200) {
      messageApi.open({
        type: "success",
        content: updateGroupProductFeatureSelector?.data?.message,
      });
      dispatch(fileUploadAction.fileUploadInfoReset());
      dispatch(
        updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset()
      );
      navigate("/dpc-extra-group");
    } else if (updateGroupProductFeatureSelector?.message) {
      messageApi.open({
        type: "error",
        content: updateGroupProductFeatureSelector?.message,
      });
      dispatch(
        updateGroupProductFeatureAction.updateGroupProductFeatureInfoReset()
      );
    }
  }, [updateGroupProductFeatureSelector]);

  return (
    <>
      {(getGroupProductFeatureContentSelector?.isLoading === true ||
        getGroupProductFeatureStateSelector?.isLoading === true ||
        updateGroupProductFeatureSelector?.isLoading === true ||
        fileUploadDataSelector?.isLoading === true ||
        loading) && <Loader loaderTransForm="loaderTransForm" />}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>
          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.PRODUCTS,
                onClick: () => navigate("/dashboard"),
              },
              {
                text: BreadCrum.MANAGE_PRODUCTS,
                onClick: () => navigate("/edit-group-product"),
              },
              {
                text: BreadCrum.DPC_EXTRA_LIST,
                onClick: () => navigate("/dpc-extra-group"),
              },
              { text: state?.selectedDiscountType, active: true },
            ]}
          />
          <div className="redirectionlink d-n one">
            <div className="sectionpadding d-block mb-30 hoversection btnPosition">
              <div className="d-flex justify-content-between align-items-center">
                <p className="mb-0 heedprodct">{state?.selectedDiscountType}</p>
              </div>
              <hr />
              <Formik
                enableReinitialize
                initialValues={{
                  discountUrlEnglish: "",
                  discountUrlSpanish: "",
                  phoneNumberEnglish: "",
                  phoneNumberSpanish: "",
                  isVirtualCareEnglish: false,
                  isVirtualCareSpanish: false,
                }}
                validationSchema={
                  state?.selectedDiscountType === "Telemedicine"
                    ? null
                    : state?.manageSwitch === false
                    ? dpcExtraDiscountValidation
                    : dpcExtraDiscountValidationSpanish
                }
                onSubmit={(values, formikBag) => {
                  handleFormSubmit(values, formikBag);
                }}
              >
                {({ values }) => (
                  <Form className="login-form">
                    {state?.selectedDiscountType !== "Telemedicine" && (
                      <div className="mb-30 d-flex fullwidth gap-20">
                        <div>
                          <Field
                            type="text"
                            placeholder="URL"
                            name={
                              state?.manageSwitch === false
                                ? "discountUrlEnglish"
                                : "discountUrlSpanish"
                            }
                            className="form-control "
                            onKeyPress={handleKeyPressSpace}
                            autoComplete="Off"
                          />

                          <ErrorMessage
                            name={
                              state?.manageSwitch === false
                                ? "discountUrlEnglish"
                                : "discountUrlSpanish"
                            }
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                        <div>
                          <Field
                            type="number"
                            name={
                              state?.manageSwitch === false
                                ? "phoneNumberEnglish"
                                : "phoneNumberSpanish"
                            }
                            placeholder="Phone Number"
                            className="form-control"
                            onKeyPress={handleKeyPressSpace}
                            onKeyDown={(event) => {
                              if (
                                ["ArrowUp", "ArrowDown"].includes(event.key)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            autoComplete="Off"
                          />
                          <ErrorMessage
                            name={
                              state?.manageSwitch === false
                                ? "phoneNumberEnglish"
                                : "phoneNumberSpanish"
                            }
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    )}

                    {state?.selectedDiscountType === "Telemedicine" && (
                      <>
                        {state?.manageSwitch === false ? (
                          <Field type="checkbox" name="isVirtualCareEnglish">
                            {({ field }) => (
                              <Checkbox {...field} checked={field.value}>
                                Virtual Care
                              </Checkbox>
                            )}
                          </Field>
                        ) : (
                          <Field type="checkbox" name="isVirtualCareSpanish">
                            {({ field }) => (
                              <Checkbox {...field} checked={field.value}>
                                Virtual Care
                              </Checkbox>
                            )}
                          </Field>
                        )}
                      </>
                    )}

                    {!(fileUploaded || spanishFileUpload) ? (
                      <Field name="file_upload ">
                        {({ field }) => (
                          <Dragger {...props} className="mb-30 d-block">
                            <p className="ant-upload-drag-icon">
                              <img src={uploadimg} alt="" />
                            </p>
                            <p className="ant-upload-text">
                              <u>Click here</u> or drag & drop to upload .PDF
                              file
                            </p>
                          </Dragger>
                        )}
                      </Field>
                    ) : (
                      <div className="ant-upload-list ant-upload-list-text">
                        <span className="ant-upload-list-item-container">
                          <div className="d-flex gap-2 align-items-center file-icon">
                            <img src={uploadimg} alt="" />
                            {fileUploaded || spanishFileUpload}
                          </div>
                          <div className="d-flex gap-2">
                            <a href={fileUploadData?.signedUrl} target="_blank">
                              <img src={eye} alt="" />
                            </a>
                            <div
                              className="cursorPointer"
                              onClick={() => deleteFile()}
                            >
                              <img src={deleteicon} alt="" />
                            </div>
                          </div>
                        </span>
                      </div>
                    )}

                    <div className="text-end">
                      <button
                        className="sbg border-0 py3 fw-18 gbo continuebtn disclaimerBtn savebtn"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDpcDiscountGroup;
