export const DPCExtraListColumn = (navigate) => [
  {
    title: "Discount components",
    dataIndex: "discountComponents",
    key: "discountComponents",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (key) => {
      return (
        <div className={key === "ACTIVE" ? "activted" : "inactive"}>{key}</div>
      );
    },
  },

  {
    title: "Action",
    render: (key) => {
      return (
        <div className="action-icons">
          <div>
            <button className="manage" onClick={()=>navigate("/edit-dpc-extra",{state:key})}>Manage</button>
          </div>
        </div>
      );
    },
  },
];
