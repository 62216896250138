import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../common/CommonTable";
import Loader from "../Loder/loader";
import { DPCExtraListColumnGroup } from "./DPCExtraListColumnGroup";
import { getGroupProductFeatureContentHandler } from "../../redux/action/getGroupProductFeatureContent";
import { getItem, removeItem } from "../../common/localStorage";

const DPCExtraGroup = () => {
  const [handleDpcExtraList, setHandleDpcExtraList] = useState([]);
  const { state } = useLocation();

  const groupProductFeatureId = getItem("groupProductFeatureId");

  const getGroupProductFeatureStateSelector = useSelector(
    (state) => state?.getGroupProductFeatureContent
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashBoardColumnsData = DPCExtraListColumnGroup(navigate);

  const addDpcExtra = () => {
    navigate("/add-dpc-group", { state: { fromDpcExtraGroup: state } });
  };

  const getGroupProductFeatueContent = () => {
    if (groupProductFeatureId) {
      let payload = {
        groupProductFeatureId: groupProductFeatureId,
      };
      dispatch(getGroupProductFeatureContentHandler(payload));
    }
  };

  useEffect(() => {
    getGroupProductFeatueContent();
  }, []);

  useEffect(() => {
    const dpcExtraList =
      getGroupProductFeatureStateSelector?.data?.data?.content?.componants
        ?.filter((item) => item?.isSelected)
        ?.map((filteredItem) => {
          return {
            ...filteredItem,
            discountComponents: filteredItem?.name,
            status: filteredItem?.status,
          };
        })
    setHandleDpcExtraList(dpcExtraList);
  }, [getGroupProductFeatureStateSelector]);

  return (
    <>
      {getGroupProductFeatureStateSelector?.isLoading === true && (
        <Loader loaderTransForm="loaderTransForm" />
      )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>

          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.GROUPS,
                onClick: () => {
                  navigate("/groups");
                  removeItem("productId");
                },
              },
              {
                text: BreadCrum.MANAGE_PRODUCTS,
                onClick: () => navigate("/edit-group-product"),
              },
              {
                text:
                  state?.fromGroupData?.featureName || BreadCrum.DPC_EXTRA,
                active: true,
              },
            ]}
          />
          <div className="table-padding">
            <div className="headtitle">
              <p className="mb-0 heedprodct">
                {state?.fromGroupData?.featureName || "DPCextra"}
              </p>
              <button className="primarybtn" onClick={addDpcExtra}>
                <div className="plus"></div>
                Add
              </button>
            </div>
            <hr className="m-0" />
            <div className="table-responsive">
              <CommonTable
                body={handleDpcExtraList}
                columns={dashBoardColumnsData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DPCExtraGroup;
