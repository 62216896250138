import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import addbtn from "../../assets/Add_Button.svg";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import CustomBreadcrumb from "../../common/CustomBreadcrumb";
import { BreadCrum } from "../../common";
import ansbtn from "../../assets/ansbtn.svg";
import copybtn from "../../assets/Copy.svg";
import deletebtn from "../../assets/Delete.svg";
import expand from "../../assets/expand.svg";
import transh from "../../assets/trash.svg";
import collaps from "../../assets/collaps.svg";
import { Checkbox } from "antd";
import SelectInput from "../../common/SelectInput";
import { v4 as uuidv4 } from "uuid";
import { handleKeyPressSpace, handlePaste } from "../../common/CommonFunctions";
import { getProductFeaturesHandler } from "../../redux/action/getproductFeaturesSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductFeatureAction,
  addProductFeaturesHandler,
} from "../../redux/action/addProductFeaturesSlice";
import { useCommonMessage } from "../../common/CommonMessage";
import { BrandChangeModal } from "../../components/BrandChangeModal/BrandChangeModal";
import { createPayload } from "../../components/Claim/claimPayload";
import { validationSchemaEditQuiz } from "../../common/Validations";
import { OPTION, questionOption } from "./QuizUtils";
import Loader from "../Loder/loader";
import { getItem, removeItem } from "../../common/localStorage";

const EditQuizForm = () => {
  const { state } = useLocation();
  const [expandCollaps, setExpandCollaps] = useState({});
  const [allFormData, setAllFormData] = useState([]);
  const [selectedQuestionType, setSelectedQuestionType] =
    useState("MultipleChoice");
  const [initialValueBoth, setInitialValueBoth] = useState([]);
  const [setsData, setSetsData] = useState([]);
  const [handleSetsData, setHandleSetsData] = useState(false);
  const [questionDeleteDataIndex, setquestionDeleteDataIndex] = useState({});
  const [tempState, setTempState] = useState(state);
  const [handleDeleteDataIndex, setHandleDeleteDataIndex] = useState({});
  const [deleteUploadedFile, setDeleteUploadedFile] = useState("");
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(false);
  const [addProductFeatureModal, setAddProductFeatureModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const messageApi = useCommonMessage();

  useEffect(() => {
    if (state?.claimData) {
      const quizData = state?.claimData?.itemStored?.quizData || [];
      const mergedData = quizData.flatMap((item) => {
        return (
          item?.quizSet?.map((quizItem) => {
            const { setQuestions, ...restQuizItem } = quizItem;

            return restQuizItem?.setName && restQuizItem?.setDescription ? {
              title: restQuizItem?.setName || "",
              description: restQuizItem?.setDescription || "",
            } : {}
          }) || []
        );
      });

      const questionsData = quizData?.flatMap((item) => {
        return (
          item?.quizSet?.flatMap((quizItem) => {
            return (
              quizItem?.setQuestions?.map((questionItem) => {
                return {
                  selectedQuestionType: questionItem?.questionType || "",
                  questionText: questionItem?.questionText || "",
                  numberOfChoice: questionItem?.numberOfChoice || 0,
                  setIndex: questionItem?.setIndex,
                  options: questionItem?.answerChoice || [],
                  correctAnswer: questionItem?.correctAnswer || "",
                  showAnswerKey: false
                };
              }) || []
            );
          }) || []
        );
      });
      setInitialValueBoth({ questions: questionsData });
      setSetsData(mergedData);
    }
  }, [allFormData, state]);

  const getProductFeaturesSelector = useSelector(
    (state) => state?.getProductFeatures
  );

  const addProductFeaturesSelector = useSelector(
    (state) => state?.addProductFeatures
  );

  const getProductId = getItem("productId");
  const refFeatureId = getItem("refFeatureId");

  const getProductFeatureFunction = () => {
    if (Number(refFeatureId)) {
      let payload = {
        productId: Number(getProductId),
        refFeatureId: Number(refFeatureId),
      };
      dispatch(getProductFeaturesHandler(payload));
    }
  };

  useEffect(() => {
    getProductFeatureFunction();
  }, []);

  useEffect(() => {
    const productFeatures =
      getProductFeaturesSelector?.data?.data?.productFeature;
    if (productFeatures?.length > 0) {
      const claimFeatures = productFeatures?.filter(
        (feature) => feature?.content?.type === "claim"
      );
      if (claimFeatures?.length > 0) {
        for (let index = 0; index < claimFeatures?.length; index++) {
          const element = claimFeatures[index];
          setAllFormData(element);
        }
      }
    }
  }, [getProductFeaturesSelector]);

  const handleFormSubmit = (values) => {
    const invalidQuestions = values.questions.filter(
      (item) =>
        item?.selectedQuestionType === "radio" &&
        (!item?.correctAnswer || item?.correctAnswer?.length === "")
    );
    if (invalidQuestions?.length > 0) {
      messageApi.open({
        type: 'warning',
        content: 'Please Add Answer Key..!',
      });
      return;
    }
    const existingClaims = allFormData?.content?.claim?.map((item) => {
      return {
        quizData: item?.quizData?.map((quizData) => {
          return {
            quizSet: quizData?.quizSet?.map((data) => {
              return {
                setQuestions: data?.setQuestions.map((quesData) => {
                  return {
                    answerChoice: quesData?.answerChoice,
                    correctAnswer: quesData?.correctAnswer,
                    numberOfChoice: quesData?.numberOfChoice,
                    questionInfo: quesData?.questionInfo,
                    questionText: quesData?.questionText,
                    questionType: quesData?.questionType,
                    setIndex: quesData?.setIndex,
                    userAnswer: quesData?.userAnswer,
                  };
                }),
                setName: data?.setName,
                setDescription: data?.setDescription,
                setStatus: data?.setStatus,
              };
            }),
            quizName: quizData?.quizName,
            quizDescription: quizData?.quizDescription,
            quizStatus: "pending"
          };
        }),
        id: item?.id,
        language: item?.language,
        claimName: item?.claimName,
        claimType: item?.claimType,
        claimStatus: item?.claimStatus || "ACTIVE",
        setQuestionsCount: item?.formData?.length,
        setAttemptedQuestionsCount: 0,
        formTitle: item?.formTitle,
        formDescription: item?.formDescription,
        urlData: item?.urlData,
        formData: item?.formData
      };
    }) || [];

    const matchSeqId = allFormData?.content?.claim?.findIndex(
      (item) => item?.id === (state?.claimData?.id || state?.id)
    );


    const groupedSets = [];
    values?.sets?.forEach((acc, index) => {
      const setName = acc?.title;
      const description = acc?.description;
      let Object = {};
      if (setName?.length && description?.length) {
        Object = {
          setName: setName,
          setDescription: description,
          setStatus: "pending",
          setQuestions: [],
        };
        const findSetWiseQuestion = values.questions?.filter(
          (item) => item?.setIndex === index
        );
        findSetWiseQuestion.forEach((question) => {
          Object.setQuestions.push({
            userAnswer: "",
            answerChoice: question?.options,
            questionInfo: "",
            setIndex: question?.setIndex,
            questionText: question?.questionText,
            questionType: question?.selectedQuestionType,
            numberOfChoice: Number(question?.numberOfChoice),
            correctAnswer: question?.correctAnswer,
          });
        });
      }
      groupedSets?.push(Object);
    });

    const newClaim = {
      quizData: [
        {
          quizName: values?.title,
          quizStatus: "pending",
          quizDescription: values?.description,
          quizSet: groupedSets, // Multiple sets added here
        },
      ],
      claimName:
        state?.values?.claimName ||
        state?.values?.claimNameSpanish ||
        state?.claimData?.claimName ||
        state?.claimData?.claimNameSpanish ||
        tempState?.values?.claimName ||
        tempState?.values?.claimNameSpanish,
      claimType:
        state?.values?.claimType ||
        state?.values?.claimTypeSpanish ||
        state?.claimData?.claimType ||
        state?.claimData?.claimTypeSpanish ||
        tempState?.values?.claimType ||
        tempState?.values?.claimTypeSpanish,
      claimStatus: "ACTIVE",
      language:
        state?.handleSwitch === false || tempState?.handleSwitch === false
          ? "en"
          : "es",
      id: state?.claimData?.id || state?.id || uuidv4(),
      urlData: "",
      formData: []
    };

    if (matchSeqId !== -1) {
      existingClaims[matchSeqId] = newClaim;
    } else {
      existingClaims.push(newClaim);
    }
    // Uncomment the line below when ready to dispatch the action
    if (!invalidQuestions?.length > 0) {
      let payload1 = createPayload(existingClaims)
      dispatch(addProductFeaturesHandler(payload1));
    }
  };


  useEffect(() => {
    if (addProductFeaturesSelector?.data?.statusCode === 200) {
      messageApi.open({
        type: "success",
        content: addProductFeaturesSelector?.data?.message,
      });
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
      setTimeout(() => {
        navigate("/claim");
      }, 1000);
      getProductFeatureFunction();
    } else if (addProductFeaturesSelector?.message) {
      messageApi.open({
        type: "error",
        content: addProductFeaturesSelector?.message,
      });
      dispatch(addProductFeatureAction.addProductFeaturesInfoReset());
    }
  }, [addProductFeaturesSelector]);

  const handleCancel = () => {
    setHandleDeleteDataIndex({});
    setAddProductFeatureModal(false);
    setDeleteQuestionModal(false);
  };
  const handleModalYes = () => {
    if (handleDeleteDataIndex?.setIndex > -1) {
      const setList = handleDeleteDataIndex?.data?.sets;
      setList?.splice(handleDeleteDataIndex?.setIndex, 1);
      const questionList = handleDeleteDataIndex?.data?.questions?.filter(
        (data) => {
          return data.setIndex !== handleDeleteDataIndex?.setIndex;
        }
      ).map((data) => {
        if (data?.setIndex === (handleDeleteDataIndex?.setIndex + 1)) {
          return { ...data, setIndex: handleDeleteDataIndex?.setIndex }
        }
        return data;
      });
      setInitialValueBoth({ questions: questionList });
      setSetsData(setList);
    }
    setAddProductFeatureModal(false);
    setDeleteUploadedFile("DeleteFile");
  };

  const handleSetDelete = () => {
    setAddProductFeatureModal(true);
    setDeleteUploadedFile("DeleteSet");
  };
  
  const addSetsBtnShow = (setRecord, setValue) => {
    const totalSet = setRecord?.filter((item) => Object.keys(item)?.length);
    const findIndex = totalSet?.findIndex(
      (val) => JSON.stringify(val) === JSON.stringify(setValue)
    );
    return findIndex === totalSet?.length - 1
  };

  const handleYesQuestions = () => {
    setDeleteQuestionModal(false);
    setDeleteUploadedFile('DeleteFile');
    questionDeleteDataIndex.remove(questionDeleteDataIndex?.index);
  };

  const questionSingleDelete = () => {
    setDeleteQuestionModal(true);
    setDeleteUploadedFile("DeleteQuestion");
  };

  const toggleExpand = (index) => {
    setExpandCollaps((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <>
      {(getProductFeaturesSelector?.isLoading ||
        addProductFeaturesSelector?.isLoading === true) && (
          <Loader loaderTransForm="loaderTransForm" />
        )}
      <div className="rightside">
        <div className="rightcontent">
          <p>
            <strong>Products</strong>
          </p>

          <CustomBreadcrumb
            items={[
              { text: BreadCrum.HOME },
              {
                text: BreadCrum.PRODUCTS,
                onClick: () => {
                  navigate("/dashboard");
                  removeItem("productId");
                },
              },
              {
                text: BreadCrum.CLAIM_LIST,
                onClick: () => navigate("/claim"),
              },
              { text: BreadCrum.EDIT_CLAIM, active: true },
            ]}
          />
          <Formik
            enableReinitialize
            initialValues={{
              title:
                tempState?.claimData?.fromClaim !== null && state?.claimData?.itemStored?.quizData?.[0]?.quizName,
              description:
                tempState?.claimData?.fromClaim !== null && state?.claimData?.itemStored?.quizData?.[0]?.quizDescription,
              sets:
                setsData?.length > 0 && tempState?.claimData?.fromClaim !== null
                  ? setsData
                  : [],
              questions:
                initialValueBoth?.questions?.length > 0 && tempState?.claimData?.fromClaim !== null
                  ? initialValueBoth?.questions
                  : [],
            }}
            validationSchema={validationSchemaEditQuiz}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ values, handleChange, handleBlur, setFieldValue, touched, errors }) => (
              <Form>
                <div className="table-padding mb-40 ">
                  {/* chip set */}
                  <div className="headtitle d-block">
                    <div className="d-flex justify-content-between align-items-center d-4grid">
                      <div>
                        <div className="productname mb-10">Claim Name </div>
                        <div className="producttitle ">
                          {state?.values?.claimName ||
                            state?.values?.claimNameSpanish}
                        </div>
                      </div>
                      <div className="verticalline mx-100"></div>
                      <div className="">
                        <div className="productname mb-10">Claim Type</div>
                        <div className="producttitle ">
                          {state?.values?.claimType ||
                            state?.values?.claimTypeSpanish}
                        </div>
                      </div>
                      <div className="verticalline mx-100"></div>
                      <div className="">
                        <div className="productname mb-10">Language</div>
                        <div className="producttitle ">
                          {state?.handleSwitch === false ? 'English' : ' Spanish'}
                        </div>
                      </div>
                      <div className="verticalline mx-100"></div>
                      <div className="">
                        <div className="productname mb-10">Claim Status</div>
                        <div className="producttitle ">ACTIVE</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sectionpadding mb-30 hoversection">
                  <div className="login-form">
                    <div className="headtitle p-0">
                      <p className="mb-0 heedprodct">
                        Quiz -{" "}
                        {state?.handleSwitch === false ||
                          tempState?.handleSwitch === false
                          ? "For English"
                          : "For Spanish"}
                      </p>
                    </div>
                    <hr />
                    <label className="mb-2">Title*</label>
                    <div className="mb-25">
                      <input
                        id="title"
                        name="title"
                        type="text"
                        placeholder="Title"
                        value={values?.title}
                        className="w-100  position-relative form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPressSpace}
                        // onPaste={handlePaste}
                        maxLength={200}
                      />
                      <ErrorMessage
                        name="title"
                        component="div"
                        className="error"
                      />
                      {values?.title?.length > 0 && (
                      <div className="character-count text-end">
                        {values?.title?.length} / 200 characters
                      </div>
                    )}
                    </div>
                    <div className="mb-25 position-relative ">
                      <label className="mb-2">Description*</label>
                      <Field
                        id="description"
                        name="description"
                        as="textarea"
                        rows={5}
                        value={values?.description}
                        placeholder="Quiz Description"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyPress={handleKeyPressSpace}
                        // onPaste={handlePaste}
                        maxLength={65000}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error"
                      />
                      {values?.description?.length > 0 && (
                      <div className="character-count text-end">
                        {values?.description?.length} / 65000 characters
                      </div>
                    )}
                    </div>
                  </div>
                </div>
                {/* Add set functionlity */}
                {handleSetsData && (
                  <div className="text-end">
                    <button
                      className="plusicon border-0 py3 fw-18 gbo ms-auto bgtransparent  addcreatebtn"
                      onClick={() => {
                        setHandleSetsData(true);
                      }}
                    >
                      <span>
                        <img src={addbtn} alt="" /> Add Set
                      </span>
                    </button>
                  </div>
                )}
                {!handleSetsData && (
                  <>
                    <FieldArray name="sets">
                      {({ push, remove, form }) => (
                        <>
                          {values?.sets?.map((item, setIndex) =>
                            Object.keys(item)?.length > 0 ? (
                              <div>
                                <div
                                  className="sectionpadding mb-30 hoversection login-form"
                                  key={setIndex}
                                >
                                  <div className="d-flex justify-content-between">
                                    <p className="mb-0 heedprodct">
                                      Set {setIndex + 1}
                                    </p>
                                    <div className="d-flex align-items-center">
                                      <div
                                        className={expandCollaps[setIndex] ? "collapsevent" : ""}
                                        onClick={() => toggleExpand(setIndex)}
                                      >
                                        <img
                                          src={expand}
                                          alt=""
                                          className="expand"
                                        />
                                        <img
                                          src={collaps}
                                          alt=""
                                          className="collaps"
                                        />
                                      </div>
                                      {setIndex > 0 && (
                                        <img
                                          src={transh}
                                          alt=""
                                          className="deleteset"
                                          onClick={() => {
                                            setHandleDeleteDataIndex({
                                              data: values,
                                              setIndex,
                                              remove,
                                              setFieldValue,
                                            });
                                            handleSetDelete();
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <hr />
                                  <label className="mb-2">Title*</label>
                                  <Field
                                    name={`sets.${setIndex}.title`}
                                    className="w-100 mb-25 position-relative form-control"
                                    type="text"
                                    placeholder="Quiz Title"
                                    onChange={form.handleChange}
                                    onKeyPress={handleKeyPressSpace}
                                    // onPaste={handlePaste}
                                    maxLength={200}
                                  />
                                  <ErrorMessage
                                    name={`sets.${setIndex}.title`}
                                    component="div"
                                    className="error"
                                  />
                                  <div className="mb-25 position-relative">
                                    <label className="mb-2">Description*</label>
                                    <Field
                                      name={`sets.${setIndex}.description`}
                                      rows={5}
                                      placeholder="Quiz Description"
                                      className="form-control"
                                      onChange={form.handleChange}
                                      onKeyPress={handleKeyPressSpace}
                                      // onPaste={handlePaste}
                                      maxLength={65000}
                                      as="textarea"
                                    />
                                    <ErrorMessage
                                      name={`sets.${setIndex}.description`}
                                      component="div"
                                      className="error"
                                    />
                                    {form.values.sets[setIndex]?.description?.length > 0 && (
                                      <div className="character-count text-end">
                                        {form.values.sets[setIndex]?.description?.length} / 65000 characters
                                      </div>
                                    )}
                                  </div>
                                  <div className="w-100 d-flex justify-content-between">
                                    <div className=" d-flex  align-items-center">
                                      {
                                        addSetsBtnShow(values?.sets, item) && (
                                          <div>
                                            <button
                                              type="button"
                                              onClick={() =>
                                                push({
                                                  title: "",
                                                  description: "",
                                                })
                                              }
                                              className="plusicon border-0 py3 fw-18 gbo ms-auto bgtransparent addcreatebtn"
                                            >
                                              <img src={addbtn} alt="" /> Add Set
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                    <div
                                      className="min-w-max plusicon addcreatebtn cursor-pointer"
                                      onClick={() => {
                                        values?.questions?.push({
                                          question: "",
                                          options: ["", ""],
                                          setIndex,
                                          selectedQuestionType: "",
                                          questionText: "",
                                          numberOfChoice: 2,
                                          correctAnswer: "",
                                          showAnswerKey: false,
                                        });

                                        setFieldValue(
                                          "questions",
                                          values?.questions
                                        );
                                      }}
                                    >
                                      <img src={addbtn} alt="" />{" "}
                                      <span>Create Questionnaire</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="position-relative">
                                  <FieldArray name="questions">
                                    {({ push, remove, form }) => (
                                      <>
                                        {values?.questions?.length > 0 &&
                                          values?.questions?.map(
                                            (item, index) => {
                                              return item?.setIndex ===
                                                setIndex ? (
                                                <>
                                                  <div
                                                    className={
                                                      expandCollaps[setIndex]
                                                        ? "sectionpadding mb-30 hoversection collapssection"
                                                        : "sectionpadding mb-30 hoversection"
                                                    }
                                                    key={index}
                                                  >
                                                    <div className="d-xxl-flex mb-30 hidesection">
                                                      <div
                                                        className=" pe-xxl-2 position-relative selecttype me-xxl-2 mb-3 mb-xxl-0 hidesection"
                                                        controlId="formBasicEmail"
                                                      >
                                                        <label className="mb-2">
                                                          Select Question Type*
                                                        </label>
                                                        <SelectInput
                                                          value={
                                                            item?.selectedQuestionType
                                                          }
                                                          options={
                                                            questionOption
                                                          }
                                                          placeholder={
                                                            "Select Question Type"
                                                          }
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            form.setFieldValue(
                                                              `questions[${index}].selectedQuestionType`,
                                                              selectedOption
                                                            );
                                                          }}
                                                        />
                                                        <ErrorMessage name={`questions[${index}].selectedQuestionType`} component="div" className="error" />
                                                      </div>

                                                      {(item?.selectedQuestionType ===
                                                        "radio" ||
                                                        item?.selectedQuestionType ===
                                                        "dropDownSingle" ||
                                                        item?.selectedQuestionType ===
                                                        "dropDownMulti" ||
                                                        item?.selectedQuestionType ===
                                                        "checkBox") && (
                                                          <div
                                                            className=" pe-xxl-2 hidesection position-relative me-xxl-2 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3 mb-xxl-0 mb-3"
                                                            controlId="formBasicEmail"
                                                          >
                                                            <label className="mb-2">
                                                              No. of Choices
                                                            </label>
                                                            <SelectInput
                                                              value={
                                                                item?.numberOfChoice
                                                              }
                                                              options={OPTION}
                                                              onChange={(
                                                                selectedOption
                                                              ) => {
                                                                const updateQuestions = form.values.questions.map((question, queIndex) => {
                                                                  if (index === queIndex) {
                                                                    question.numberOfChoice = selectedOption;
                                                                    question.options = Array(Number(selectedOption)).fill('');
                                                                    question.correctAnswer = '';
                                                                  }
                                                                  return question;
                                                                });
                                                                form.setValues({
                                                                  ...form.values,
                                                                  question: updateQuestions
                                                                });
                                                              }}
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                    {item?.selectedQuestionType ===
                                                      "radio" && (
                                                        <div>
                                                          <div
                                                            controlId="formBasicEmail"
                                                            className="login-form mb-30"
                                                          >
                                                            <label className="mb-2">
                                                              Question*
                                                            </label>
                                                            <Field
                                                              className=" selectdisplayed form-control"
                                                              type="text"
                                                              placeholder="Untitled Question"
                                                              name={`questions[${index}].questionText`}
                                                              onChange={(e) => {
                                                                const {
                                                                  name,
                                                                  value,
                                                                } = e.target;
                                                                form.setFieldValue(
                                                                  `questions[${index}].${name}`,
                                                                  value
                                                                );
                                                                handleChange(e);
                                                              }}
                                                              onBlur={handleBlur}
                                                              onKeyPress={
                                                                handleKeyPressSpace
                                                              }
                                                              // onPaste={
                                                              //   handlePaste
                                                              // }
                                                              maxLength={100}
                                                            />
                                                            <ErrorMessage name={`questions[${index}].questionText`} component="div" className="error" />
                                                          </div>
                                                          {Number(
                                                            item?.numberOfChoice
                                                          ) > 0 && (
                                                              <div
                                                                className=" pe-2 position-relative optionselection  hidesection"
                                                                controlId="formBasicEmail"
                                                              >
                                                                <label className="mb-2">
                                                                  Options*{" "}
                                                                </label>
                                                                <ul className="p-0">
                                                                  {[
                                                                    ...Array(
                                                                      Number(
                                                                        item?.numberOfChoice
                                                                      )
                                                                    ),
                                                                  ]?.map(
                                                                    (_, indexs) => (
                                                                      <li
                                                                        key={indexs}
                                                                      >
                                                                        <Checkbox className="">
                                                                          <div className="checkradiobtn d-inline-block"></div>
                                                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                                                            <Field
                                                                              type="text"
                                                                              placeholder={`Option ${indexs +
                                                                                1
                                                                                }`}
                                                                              name={`questions[${index}].options[${indexs}]`}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                const newOptions =
                                                                                  [
                                                                                    ...(item?.options ||
                                                                                      []),
                                                                                  ];
                                                                                newOptions[
                                                                                  indexs
                                                                                ] =
                                                                                  e?.target?.value;
                                                                                form.setFieldValue(
                                                                                  `questions[${index}].options`,
                                                                                  newOptions
                                                                                );
                                                                                // setInitialOption(newOptions)
                                                                                form.setFieldValue(`questions[${index}].correctAnswer`, "");
                                                                                // setSelectedOptions([])
                                                                              }}
                                                                              onBlur={
                                                                                handleBlur
                                                                              }
                                                                              onKeyPress={
                                                                                handleKeyPressSpace
                                                                              }
                                                                              // onPaste={
                                                                              //   handlePaste
                                                                              // }
                                                                            />
                                                                          </div>
                                                                        </Checkbox>
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>
                                                                {touched?.questions?.[index]?.options && (
                                                                  <div className="error">
                                                                    {Array.isArray(
                                                                      errors?.questions?.[index]?.options
                                                                    )
                                                                      ? 'Options are required'
                                                                      : errors?.questions?.[index]?.options}
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )}
                                                        </div>
                                                      )}
                                                    {item?.selectedQuestionType ===
                                                      "checkBox" && (
                                                        <div>
                                                          <div
                                                            controlId="formBasicEmail"
                                                            className="login-form mb-30"
                                                          >
                                                            <label className="mb-2">
                                                              Question*{" "}
                                                            </label>
                                                            <Field
                                                              className=" selectdisplayed form-control"
                                                              type="text"
                                                              placeholder="Untitled Question"
                                                              name={`questions[${index}].questionText`}
                                                              onChange={(e) => {
                                                                const {
                                                                  name,
                                                                  value,
                                                                } = e.target;
                                                                form.setFieldValue(
                                                                  `questions[${index}].${name}`,
                                                                  value
                                                                );
                                                                handleChange(e);
                                                              }}
                                                              onBlur={handleBlur}
                                                              onKeyPress={
                                                                handleKeyPressSpace
                                                              }
                                                              // onPaste={
                                                              //   handlePaste
                                                              // }
                                                              maxLength={100}
                                                            />
                                                            <ErrorMessage name={`questions[${index}].questionText`} component="div" className="error" />
                                                          </div>

                                                          {Number(
                                                            item?.numberOfChoice
                                                          ) > 0 && (
                                                              <div
                                                                className=" pe-2 position-relative optionselection hidesection"
                                                                controlId="formBasicEmail"
                                                              >
                                                                <label className="mb-2">
                                                                  Options*{" "}
                                                                </label>
                                                                <ul className="p-0">
                                                                  {[
                                                                    ...Array(
                                                                      Number(
                                                                        item?.numberOfChoice
                                                                      )
                                                                    ),
                                                                  ].map(
                                                                    (_, indexs) => (
                                                                      <li
                                                                        key={indexs}
                                                                      >
                                                                        <Checkbox>
                                                                          <div className="checkradiobtn d-inline-block"></div>
                                                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                                                            <Field
                                                                              type="text"
                                                                              name={`questions[${index}].options[${indexs}]`}
                                                                              placeholder={`Option ${indexs +
                                                                                1
                                                                                }`}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                const newOptions =
                                                                                  [
                                                                                    ...(item?.options || []),
                                                                                  ];
                                                                                newOptions[
                                                                                  indexs
                                                                                ] =
                                                                                  e?.target?.value;
                                                                                form.setFieldValue(
                                                                                  `questions[${index}].options`,
                                                                                  newOptions
                                                                                );
                                                                              }}
                                                                              onBlur={
                                                                                handleBlur
                                                                              }
                                                                              onKeyPress={
                                                                                handleKeyPressSpace
                                                                              }
                                                                              // onPaste={
                                                                              //   handlePaste
                                                                              // }
                                                                            />
                                                                          </div>
                                                                        </Checkbox>
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>
                                                                {touched?.questions?.[index]?.options && (
                                                                  <div className="error">
                                                                    {Array.isArray(
                                                                      errors?.questions?.[index]?.options
                                                                    )
                                                                      ? 'Options are required'
                                                                      : errors?.questions?.[index]?.options}
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )}
                                                        </div>
                                                      )}
                                                    {item?.selectedQuestionType ===
                                                      "dropDownSingle" && (
                                                        <div>
                                                          <div
                                                            controlId="formBasicEmail"
                                                            className="login-form mb-30"
                                                          >
                                                            <label className="mb-2">
                                                              Question*{" "}
                                                            </label>
                                                            <Field
                                                              className=" selectdisplayed form-control"
                                                              type="text"
                                                              placeholder="Untitled Question"
                                                              id="questionText"
                                                              name={`questions[${index}].questionText`}
                                                              onChange={(e) => {
                                                                const {
                                                                  name,
                                                                  value,
                                                                } = e.target;
                                                                form.setFieldValue(
                                                                  `questions[${index}].${name}`,
                                                                  value
                                                                );
                                                                handleChange(e);
                                                              }}
                                                              onBlur={handleBlur}
                                                              onKeyPress={
                                                                handleKeyPressSpace
                                                              }
                                                              // onPaste={
                                                              //   handlePaste
                                                              // }
                                                              maxLength={100}
                                                            />
                                                            <ErrorMessage name={`questions[${index}].questionText`} component="div" className="error" />
                                                          </div>

                                                          {Number(
                                                            item?.numberOfChoice
                                                          ) > 0 && (
                                                              <div
                                                                className=" pe-2 position-relative optionselection hidesection"
                                                                controlId="formBasicEmail"
                                                              >
                                                                <label className="mb-2">
                                                                  Options*{" "}
                                                                </label>
                                                                <ul className="p-0">
                                                                  {[
                                                                    ...Array(
                                                                      Number(
                                                                        item?.numberOfChoice
                                                                      )
                                                                    ),
                                                                  ].map(
                                                                    (_, indexs) => (
                                                                      <li
                                                                        key={indexs}
                                                                      >
                                                                        <Checkbox>
                                                                          <div className="checkradiobtn d-inline-block"></div>
                                                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                                                            <Field
                                                                              type="text"
                                                                              name={`questions[${index}].options[${indexs}]`}
                                                                              placeholder={`Option ${index +
                                                                                1
                                                                                }`}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                const newOptions =
                                                                                  [
                                                                                    ...item?.options,
                                                                                  ];
                                                                                newOptions[
                                                                                  indexs
                                                                                ] =
                                                                                  e?.target?.value;
                                                                                form.setFieldValue(
                                                                                  `questions[${index}].options`,
                                                                                  newOptions
                                                                                );
                                                                              }}
                                                                              onBlur={
                                                                                handleBlur
                                                                              }
                                                                              onKeyPress={
                                                                                handleKeyPressSpace
                                                                              }
                                                                              // onPaste={
                                                                              //   handlePaste
                                                                              // }
                                                                            />
                                                                          </div>
                                                                        </Checkbox>
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>
                                                                {touched?.questions?.[index]?.options && (
                                                                  <div className="error">
                                                                    {Array.isArray(
                                                                      errors?.questions?.[index]?.options
                                                                    )
                                                                      ? 'Options are required'
                                                                      : errors?.questions?.[index]?.options}
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )}
                                                        </div>
                                                      )}
                                                    {item?.selectedQuestionType ===
                                                      "dropDownMulti" && (
                                                        <div>
                                                          <div
                                                            controlId="formBasicEmail"
                                                            className="login-form mb-30"
                                                          >
                                                            <label className="mb-2">
                                                              Question*{" "}
                                                            </label>
                                                            <Field
                                                              className=" selectdisplayed form-control"
                                                              type="text"
                                                              placeholder="Untitled Question"
                                                              id="questionText"
                                                              name={`questions[${index}].questionText`}
                                                              onChange={(e) => {
                                                                const {
                                                                  name,
                                                                  value,
                                                                } = e.target;
                                                                form.setFieldValue(
                                                                  `questions[${index}].${name}`,
                                                                  value
                                                                );
                                                                handleChange(e);
                                                              }}
                                                              onBlur={handleBlur}
                                                              onKeyPress={
                                                                handleKeyPressSpace
                                                              }
                                                              // onPaste={
                                                              //   handlePaste
                                                              // }
                                                              maxLength={100}
                                                            />
                                                            <ErrorMessage name={`questions[${index}].questionText`} component="div" className="error" />
                                                          </div>

                                                          {Number(
                                                            item?.numberOfChoice
                                                          ) > 0 && (
                                                              <div
                                                                className=" pe-2 position-relative optionselection hidesection"
                                                                controlId="formBasicEmail"
                                                              >
                                                                <label className="mb-2">
                                                                  Options*{" "}
                                                                </label>
                                                                <ul className="p-0">
                                                                  {[
                                                                    ...Array(
                                                                      Number(
                                                                        item?.numberOfChoice
                                                                      )
                                                                    ),
                                                                  ].map(
                                                                    (_, indexs) => (
                                                                      <li
                                                                        key={indexs}
                                                                      >
                                                                        <Checkbox>
                                                                          <div className="checkradiobtn d-inline-block"></div>
                                                                          <div className="d-flex align-items-center justify-content-between w-cal">
                                                                            <Field
                                                                              type="text"
                                                                              placeholder={`Option ${index +
                                                                                1
                                                                                }`}
                                                                              name={`questions[${index}].options[${indexs}]`}
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                const newOptions =
                                                                                  [
                                                                                    ...item?.options,
                                                                                  ];
                                                                                newOptions[
                                                                                  indexs
                                                                                ] =
                                                                                  e?.target?.value;
                                                                                form.setFieldValue(
                                                                                  `questions[${index}].options`,
                                                                                  newOptions
                                                                                );
                                                                              }}
                                                                              onBlur={
                                                                                handleBlur
                                                                              }
                                                                              onKeyPress={
                                                                                handleKeyPressSpace
                                                                              }
                                                                              // onPaste={
                                                                              //   handlePaste
                                                                              // }
                                                                            />
                                                                          </div>
                                                                        </Checkbox>
                                                                      </li>
                                                                    )
                                                                  )}
                                                                </ul>
                                                                {touched?.questions?.[index]?.options && (
                                                                  <div className="error">
                                                                    {Array.isArray(
                                                                      errors?.questions?.[index]?.options
                                                                    )
                                                                      ? 'Options are required'
                                                                      : errors?.questions?.[index]?.options}
                                                                  </div>
                                                                )}
                                                              </div>
                                                            )}
                                                        </div>
                                                      )}
                                                    <hr className="mb-2 hidesection" />
                                                    <div className="w-100 d-flex justify-content-between iconsizeresponsive hidesection">
                                                      {item?.selectedQuestionType ===
                                                        "radio" && item?.options?.filter((item) => !item).length <= 1 && (
                                                          <>
                                                            <div className=" d-flex align-items-center">
                                                              <div
                                                                className="plusicon anskey"
                                                                onClick={() => {
                                                                  values.questions[index].showAnswerKey = !values.questions[index].showAnswerKey;
                                                                  if (typeof values.questions[index].correctAnswer === 'string') {
                                                                    values.questions[index].correctAnswer = values.questions[index].correctAnswer;
                                                                  }
                                                                  setFieldValue(
                                                                    "questions",
                                                                    values?.questions
                                                                  );
                                                                }}
                                                              >
                                                                <img
                                                                  src={ansbtn}
                                                                  alt=""
                                                                />
                                                                <span>
                                                                  Answer key
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                      <div className=" d-flex align-items-center">
                                                        {/* {index ===
                                                          values?.questions
                                                            ?.length -
                                                          1 && ( */}
                                                        <button
                                                          className="plusicon faqIcon me-20 cursor-pointer"
                                                          type="button"
                                                          onClick={() => {
                                                            values?.questions.push(
                                                              {
                                                                question: "",
                                                                options: ["", ""],
                                                                setIndex,
                                                                selectedQuestionType:
                                                                  "",
                                                                questionText:
                                                                  "",
                                                                numberOfChoice: 2,
                                                                correctAnswer:
                                                                  "",
                                                                showAnswerKey: false,
                                                              }
                                                            );
                                                            setFieldValue(
                                                              "questions",
                                                              values?.questions
                                                            );
                                                          }}>
                                                          <img src={addbtn} alt="" />
                                                        </button>
                                                        {/* )} */}
                                                        <button
                                                          className="plusicon faqIcon me-20 cursor-pointer"
                                                          type="button"
                                                          onClick={() => {
                                                            const newQuestion =
                                                            {
                                                              ...item,
                                                            };
                                                            push(newQuestion);
                                                          }}>
                                                          <img
                                                            src={copybtn}
                                                            alt=""
                                                          />
                                                        </button>
                                                        {/* {index !== 0 && ( */}
                                                        <button
                                                          className="plusicon faqIcon me-20 cursor-pointer"
                                                          type="button"
                                                          onClick={() => {
                                                            setquestionDeleteDataIndex(
                                                              {
                                                                index,
                                                                remove,
                                                              }
                                                            );
                                                            questionSingleDelete();
                                                          }}>
                                                          <img
                                                            src={deletebtn}
                                                            alt=""
                                                          />
                                                        </button>
                                                        {/* )} */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {
                                                    item?.showAnswerKey &&
                                                    item?.options?.length > 0 &&
                                                    item?.selectedQuestionType === 'radio' &&
                                                    (item?.selectedQuestionType === 'radio' ||
                                                      selectedQuestionType === 'radio') && (
                                                      <div>

                                                        <div className="sectionpadding mb-30">
                                                          <div className="headtitle p-0">
                                                            <p className="mb-0 heedprodct">
                                                              Select Correct Answer
                                                            </p>
                                                          </div>
                                                          <hr />
                                                          <div
                                                            controlId="formBasicEmail"
                                                            className="login-form mb-30"
                                                          >
                                                            <div className="">
                                                              {item?.questionText}
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="pe-2 position-relative optionselection hidesection"
                                                            controlId="formBasicEmail"
                                                          >
                                                            <ul className="p-0">
                                                              {[
                                                                ...Array(
                                                                  Number(item?.numberOfChoice) ||
                                                                  2
                                                                ),
                                                              ]?.map(
                                                                (_, indexs) =>
                                                                  item?.options[indexs] && (
                                                                    <li key={indexs}>
                                                                      <Checkbox
                                                                        className={`checkbox ${item?.correctAnswer?.includes(item?.options[indexs]) ? "ansSelected" : ""}`}
                                                                        onClick={() => {
                                                                          const currentOptions = [];
                                                                          currentOptions.push(item?.options[indexs])
                                                                          form.setFieldValue(`questions[${index}].correctAnswer`, currentOptions.join(""));
                                                                        }}
                                                                      >
                                                                        <div className="checkradiobtn d-inline-block"></div>
                                                                        <div className="d-flex align-items-center justify-content-between w-cal">
                                                                          <div
                                                                            name={`questions[${index}].options[${indexs}]`}
                                                                          >
                                                                            {item?.options?.[
                                                                              indexs
                                                                            ] ||
                                                                              `Option ${indexs + 1}`}
                                                                          </div>
                                                                        </div>
                                                                      </Checkbox>
                                                                    </li>
                                                                  )
                                                              )}
                                                            </ul>
                                                          </div>
                                                          <div className="text-end">
                                                            <button
                                                              className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
                                                              onClick={() => {
                                                                values.questions[index].showAnswerKey = !values.questions[index].showAnswerKey;
                                                                setFieldValue(
                                                                  "questions",
                                                                  values?.questions
                                                                );
                                                              }}
                                                              type="button"
                                                            >
                                                              Done
                                                            </button>
                                                          </div>
                                                        </div>

                                                      </div>
                                                    )}
                                                </>
                                              ) : null;
                                            }
                                          )}
                                      </>
                                    )}
                                  </FieldArray>
                                </div>
                              </div>
                            ) : null
                          )}
                        </>
                      )}
                    </FieldArray>

                    {/* Create questions */}
                    <div className="text-end">
                      <button
                        className="sbg border-0 py3 fw-18 gbo continuebtn savebtn"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {addProductFeatureModal && (
        <BrandChangeModal
          addProductFeatureModal={addProductFeatureModal}
          handleCancel={handleCancel}
          handleModalYes={handleModalYes}
          deleteUploadedFile={deleteUploadedFile}
        />
      )}
      {deleteQuestionModal && (
        <BrandChangeModal
          addProductFeatureModal={deleteQuestionModal}
          handleCancel={handleCancel}
          handleModalYes={handleYesQuestions}
          deleteUploadedFile={deleteUploadedFile}
        />
      )}
    </>
  );
};

export default EditQuizForm;

